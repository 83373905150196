import type { ReactNode } from 'react';

import classnames from 'classnames';
import { GLOBAL } from 'saddlebag-browser';

import BpkCard from '@skyscanner/backpack-web/bpk-component-card';

import { getRadioStyle } from '../../../hotels-legacy/client/src/components/CardImageMobile';
import { withI18n } from '../../../hotels-legacy/client/src/skyscanner-application/i18n';
import { getIds, getId } from '../../../hotels-legacy/client/src/utils/a11y';

import BasicInfo from './BasicInfo';

import type { I18n } from 'common-types/types/hotels-components/types';

import STYLES from './CardLayoutMobile.module.scss';

type CardLayoutMobileProps = {
  imageSlider?: ReactNode;
  ratingNode?: ReactNode;
  priceInfo?: ReactNode;
  otherPrices?: ReactNode;
  confidentMsg?: ReactNode;
  nameStarNode?: ReactNode;
  url?: string;
  cardHighlightClsName?: string;
  onClick?: Function;
  distanceNode?: ReactNode;
  hotelCardAccessibility?: boolean;
  imageRadio?: string;
  index?: number;
  i18n: I18n;
  viewedHotelCard?: boolean;
};

const CardLayoutMobile = ({
  cardHighlightClsName = undefined,
  confidentMsg = undefined,
  distanceNode = undefined,
  hotelCardAccessibility = undefined,
  i18n,
  imageRadio = undefined,
  imageSlider = undefined,
  index = undefined,
  nameStarNode = undefined,
  onClick = undefined,
  otherPrices = undefined,
  priceInfo = undefined,
  ratingNode = undefined,
  url = undefined,
  viewedHotelCard = undefined,
}: CardLayoutMobileProps) => {
  const window = GLOBAL.getWindow();
  const isSupportScrollRestoration =
    window && window.history && window.history.scrollRestoration;
  if (isSupportScrollRestoration) {
    window.history.scrollRestoration = 'manual';
  }

  const ratingNodes = (
    <div className={STYLES.CardLayoutMobile__ratingAndConfident}>
      {ratingNode}
      {confidentMsg}
    </div>
  );

  const style = { boxShadow: '0 1px 3px 0 rgba(37, 32, 31, 0.3' };
  const optionLabel =
    index === undefined
      ? undefined
      : i18n.translate(
          'aria_option',
          {
            number: index + 1,
          },
          '{',
          '}',
          true,
        );
  const headerIdPrefixs = [
    'hotel_name',
    'hotel_star',
    'hotel_price',
    'hotel_nights',
    'flight_cugs',
    'direct_discount',
    'hotel_partner',
    'hotel_original_price',
  ];

  const showHotelInfo =
    (viewedHotelCard && (ratingNode || confidentMsg || distanceNode)) ||
    !viewedHotelCard;

  return (
    <div
      className={classnames(STYLES.CardLayoutMobile, cardHighlightClsName)}
      style={style}
    >
      <div
        className={STYLES.CardLayoutMobile__image}
        {...getRadioStyle(imageRadio)}
        aria-hidden
      >
        {imageSlider}
      </div>
      <div
        className={classnames(STYLES.CardLayoutMobile__baseInfo, {
          [STYLES['CardLayoutMobile__baseInfo--accessibility']]:
            hotelCardAccessibility,
        })}
      >
        {showHotelInfo && (
          <BpkCard
            className={classnames(STYLES.CardLayoutMobile__card, {
              [STYLES['CardLayoutMobile__card--accessibility']]:
                hotelCardAccessibility,
            })}
            padded={false}
            href={url}
            target="_blank"
            data-test-id="hotel-card"
            rel="opener"
            onClick={() => {
              if (onClick !== null)
                onClick?.(true, null, true, 'baseInfoOnMobile');
            }}
            atomic={false}
            role="link"
            aria-label={optionLabel}
          >
            {nameStarNode && (
              <div
                aria-labelledby={getIds(headerIdPrefixs, index)}
                role="heading"
                aria-level={3}
              >
                {nameStarNode}
              </div>
            )}

            {hotelCardAccessibility ? (
              <BasicInfo
                ratingNode={ratingNode}
                confidentMsg={confidentMsg}
                distanceNode={distanceNode}
                hotelCardAccessibility={hotelCardAccessibility}
                index={index}
              />
            ) : (
              <>
                {ratingNodes}
                {distanceNode}
              </>
            )}
            {priceInfo && (
              <div className={STYLES.CardLayoutMobile__priceInfo}>
                {priceInfo}
              </div>
            )}
            {otherPrices}
            <div
              className={STYLES.CardLayoutMobile__screenReaderOnly}
              aria-labelledby={getId('distance', index)}
            />
            <button
              type="button"
              className={STYLES.CardLayoutMobile__screenReaderOnly}
            >
              {i18n.translate('button_select')}
            </button>
          </BpkCard>
        )}
      </div>
    </div>
  );
};

export default withI18n(CardLayoutMobile);
