import DirectDiscount from '../DirectDiscount';
import discountType from '../DirectDiscount/discountType';

import type { GeneralCoupon } from 'common-types/types/hotels-components/types';

type Props = {
  coupon?: GeneralCoupon;
  partnerName?: string;
  index?: number;
};

const GeneralDirectDiscount = ({
  coupon = undefined,
  index = undefined,
  partnerName = undefined,
}: Props) => {
  if (!coupon || !coupon.originalPrice) {
    return null;
  }

  return (
    (coupon.discountType === discountType.DIRECT_DISCOUNT && (
      <DirectDiscount
        // @ts-ignore TODO: Property 'partnerName' does not exist on type, need to update the component withTimeRemain
        coupon={coupon}
        partnerName={partnerName}
        index={index}
      />
    )) ||
    null
  );
};

export default GeneralDirectDiscount;
