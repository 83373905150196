import type { ReactNode } from 'react';

import BpkCard from '@skyscanner/backpack-web/bpk-component-card';

import STYLES from './MobileMapCardLayout.module.scss';

type MobileMapCardLayoutProps = {
  id: string;
  imageNode?: ReactNode;
  ratingNode?: ReactNode;
  priceInfo?: ReactNode;
  distanceNode?: ReactNode;
  confidentMsg?: ReactNode;
  url?: string;
  onClick?: Function;
  hotelName: string;
  starsNode?: ReactNode;
};

const MobileMapCardLayout = ({
  confidentMsg = undefined,
  distanceNode = undefined,
  hotelName,
  id,
  imageNode = undefined,
  onClick = undefined,
  priceInfo = undefined,
  ratingNode = undefined,
  starsNode = undefined,
  url = undefined,
}: MobileMapCardLayoutProps) => {
  const cardClassNames = STYLES.MobileMapCardLayout;

  return (
    <BpkCard
      id={`card${id}`}
      className={cardClassNames}
      padded={false}
      href={url}
      target="_blank"
      data-test-id="hotel-card"
      onClick={() => {
        onClick?.(true, null, true, 'baseInfoOnMobileMap');
      }}
    >
      <div className={STYLES.MobileMapCardLayout__image}>
        {imageNode}
        <div className={STYLES.MobileMapCardLayout__nameAndStar}>
          <div className={STYLES.MobileMapCardLayout__hotelName}>
            {hotelName}
          </div>
          <div className={STYLES.MobileMapCardLayout__stars}>{starsNode}</div>
        </div>
        <div className={STYLES.MobileMapCardLayout__clean}>{confidentMsg}</div>
      </div>
      <BpkCard className={STYLES.MobileMapCardLayout__baseInfo} padded={false}>
        <div className={STYLES.MobileMapCardLayout__ratingAndDistance}>
          {ratingNode}
          {distanceNode}
        </div>
        <div className={STYLES.MobileMapCardLayout__price}>
          <div className={STYLES['MobileMapCardLayout__price--info']}>
            {priceInfo}
          </div>
        </div>
      </BpkCard>
    </BpkCard>
  );
};

export default MobileMapCardLayout;
