import classnames from 'classnames';

import DefaultImageLarge from '../img/lg/default-image.svg';
import DefaultImageSmall from '../img/sm/default-image.svg';

import STYLES from './AlternativeImage.module.scss';

type Props = {
  className?: string;
  large?: boolean;
  alt?: string;
  width?: number;
  height?: number;
};

const AlternativeImage = ({
  alt = undefined,
  className = undefined,
  height = undefined,
  large = true,
  width = undefined,
}: Props) => (
  <img
    src={large ? DefaultImageLarge : DefaultImageSmall}
    className={classnames(STYLES.AlternativeImage, className)}
    alt={alt}
    width={width}
    height={height}
  />
);

export default AlternativeImage;
