import { forwardRef } from 'react';
import type { ReactNode, ForwardedRef } from 'react';

import classnames from 'classnames';

import STYLES from './NoneStyleButton.module.scss';

type Props = {
  onClick?: any;
  children?: ReactNode;
  className?: string;
};

const NoneStyleButton = (
  { children = undefined, className = undefined, onClick = undefined }: Props,
  ref: ForwardedRef<HTMLButtonElement>,
) => (
  <button
    type="button"
    ref={ref}
    onClick={onClick}
    className={classnames(STYLES.NoneStyleButton, className)}
  >
    {children}
  </button>
);

export default forwardRef(NoneStyleButton);
