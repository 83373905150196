import type { ReactNode } from 'react';
import { Component } from 'react';

import classnames from 'classnames';
import ImageGallery from 'react-image-gallery';

import BpkCard from '@skyscanner/backpack-web/bpk-component-card';
import { isRTL } from '@skyscanner/backpack-web/bpk-react-utils';

import CardImageMobile from '../../../hotels-legacy/client/src/components/CardImageMobile';
import { withI18n } from '../../../hotels-legacy/client/src/skyscanner-application/i18n';
import { InlineTextLabel2 } from '../../HotelsCommon/Text';
import NameStars from '../NameStars';

import type { I18n } from 'common-types/types/hotels-components/types';
import type { ReactImageGalleryItem } from 'react-image-gallery';

import STYLES from './CardImagesSlider.module.scss';

type CardImagesSliderProps = {
  i18n: I18n;
  images: string[];
  hotelName: string;
  detailUrl: string;
  stars?: ReactNode;
  onClick?: Function;
  isNewMapHotelInfo?: boolean;
  hotelCardAccessibility?: boolean;
  imageRadio?: string;
};

type CardImagesSliderState = {
  currentImageIndex: number;
};

class CardImagesSlider extends Component<
  CardImagesSliderProps,
  CardImagesSliderState
> {
  static defaultProps = {
    stars: undefined,
    onClick: () => {},
    isNewMapHotelInfo: false,
    hotelCardAccessibility: false,
    imageRadio: undefined,
  };

  constructor(props: CardImagesSliderProps) {
    super(props);

    this.state = {
      currentImageIndex: 0,
    };
  }

  cardImageNode = (imageUrl?: string) => {
    const { imageRadio, isNewMapHotelInfo } = this.props;

    return (
      <CardImageMobile
        image={imageUrl}
        radio={imageRadio}
        className={STYLES.CardImagesSlider__img}
        isNewMapHotelInfo={isNewMapHotelInfo}
      />
    );
  };

  onSlide = (currentIndex: number) => {
    this.setState({ currentImageIndex: currentIndex });
  };

  hotelNameStarsNodes = (total?: number) => {
    const { hotelCardAccessibility, hotelName, isNewMapHotelInfo, stars } =
      this.props;

    const { currentImageIndex } = this.state;
    const isLastImage = currentImageIndex === total;

    if (hotelCardAccessibility) {
      return null;
    }
    return (
      <NameStars
        hotelName={hotelName}
        stars={stars}
        isNewMapHotelInfo={isNewMapHotelInfo}
        isLastImage={isLastImage}
      />
    );
  };

  renderItem = (item: ReactImageGalleryItem) => {
    const { detailUrl, hotelCardAccessibility, i18n, onClick } = this.props;

    const moreDetail = (
      <div className={STYLES.CardImagesSlider__moreDetailContainer}>
        <InlineTextLabel2 className={STYLES.CardImagesSlider__moreDetail}>
          {i18n.translate('Search_image_label')}
        </InlineTextLabel2>
      </div>
    );

    return (
      <BpkCard
        className={classnames(STYLES.CardImagesSlider__card, {
          [STYLES['CardImagesSlider__card--accessibility']]:
            hotelCardAccessibility,
        })}
        padded={false}
        href={detailUrl}
        rel="opener"
        target="_blank"
        data-testid="image-mobile-hotel-card"
        onClick={() => {
          onClick?.(true, null, true, 'imageOnMobile');
        }}
      >
        {this.cardImageNode(item.original) || ''}
        {/* @ts-ignore */}
        {item.isLastImage && (
          <div className={STYLES.CardImagesSlider__detail}>{moreDetail}</div>
        )}
      </BpkCard>
    );
  };

  render() {
    const {
      detailUrl,
      hotelCardAccessibility,
      images,
      isNewMapHotelInfo,
      onClick,
    } = this.props;

    if (!images || images.length === 0 || isNewMapHotelInfo) {
      return (
        <div className={STYLES.CardImagesSlider}>
          <BpkCard
            className={STYLES.CardImagesSlider__card}
            padded={false}
            href={detailUrl}
            target="_blank"
            data-testid="image-mobile-hotel-card"
            onClick={() => {
              onClick?.(true, null, true, 'imageOnMobile');
            }}
          >
            {images?.length > 0 && this.cardImageNode(images[0])}
          </BpkCard>
          {this.hotelNameStarsNodes()}
        </div>
      );
    }

    const subImages = images.slice(0, 3);
    const subImagesNumber = subImages.length;
    const lastItem = {
      original: subImages.slice(subImagesNumber - 1)[0],
      isLastImage: true,
      url: detailUrl,
    };
    const items = subImages.map((url, i) => ({
      isFirstImage: i === 0,
      original: url,
      isLastImage: false,
      url: detailUrl,
    }));
    let showItems;
    if (items.length > 1) {
      showItems = [...items, lastItem];
    } else {
      showItems = [...items];
    }

    return (
      <div
        className={classnames(STYLES.CardImagesSlider, {
          [STYLES['CardImagesSlider--accessibility']]: hotelCardAccessibility,
        })}
      >
        <ImageGallery
          showNav={false}
          showThumbnails={false}
          showFullscreenButton={false}
          showPlayButton={false}
          isRTL={isRTL()}
          infinite={isRTL()}
          items={showItems}
          renderItem={this.renderItem}
          showBullets={showItems.length > 1}
          onSlide={this.onSlide}
          // @ts-ignore
          bulletClass={STYLES.CardImagesSlider__bullet}
        />
        {this.hotelNameStarsNodes(subImagesNumber)}
      </div>
    );
  }
}

export default withI18n(CardImagesSlider);
