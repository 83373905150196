import classnames from 'classnames';

import NewWindowIconSm from '@skyscanner/backpack-web/bpk-component-icon/sm/new-window';
import BpkLink from '@skyscanner/backpack-web/bpk-component-link/src/BpkLink';

import { isShowHotelName } from '../../../hotels-legacy/client/src/services/officialWebsite';
import {
  FormattedMessage,
  withI18n,
} from '../../../hotels-legacy/client/src/skyscanner-application/i18n';
import { InlineTextLabel1, InlineTextXs } from '../../HotelsCommon/Text';
import NormalRates from '../NormalRates';

import type { I18n, Price } from 'common-types/types/hotels-components/types';

import STYLES from './OtherRatesRow.module.scss';

const MAX_NUM_THRESHOLD = 4;

type RateProps = {
  i18n: I18n;
  rate?: Price;
  detailUrl?: string;
  onClick?: Function;
  lessNum?: number;
};

const Rate = ({
  detailUrl = undefined,
  i18n,
  lessNum = undefined,
  onClick = undefined,
  rate = undefined,
}: RateProps) => {
  const showDirectLabel =
    rate && isShowHotelName(rate.isOfficial, rate.partnerId, rate?.partnerType);

  const partneName = (
    <InlineTextXs className={STYLES.RateRow__name}>{rate?.name}</InlineTextXs>
  );

  const partnerNameWithFeature = (
    <div className={STYLES.RateRow__direct}>
      {partneName}
      {rate?.funnelType === 'dbook' ? null : (
        <NewWindowIconSm className={STYLES.RateRow__newWindowIconSmall} />
      )}
    </div>
  );

  const directLable = (
    <div className={STYLES.RateRow__direct}>
      <InlineTextXs>
        <FormattedMessage label="OTARates_label_direct" />
      </InlineTextXs>
      <NewWindowIconSm className={STYLES.RateRow__newWindowIconSmall} />
    </div>
  );

  const url =
    rate?.funnelType === 'dbook' ? detailUrl : rate?.deepLink || detailUrl;

  return (
    <BpkLink
      className={classnames(STYLES.RateRow, {
        [STYLES[`RateRow--lessNum${lessNum}`]]: lessNum && lessNum <= 3,
      })}
      href={url}
      blank
      onClick={(e: MouseEvent) => {
        e.stopPropagation();
        if (onClick !== null)
          onClick?.(false, rate, rate?.funnelType === 'dbook', 'other');
      }}
    >
      {showDirectLabel ? directLable : partnerNameWithFeature}
      <InlineTextLabel1>
        {rate && i18n.formatCurrency(rate.price)}
      </InlineTextLabel1>
    </BpkLink>
  );
};

type OtherRatesRowProps = {
  i18n: I18n;
  otherRates?: Price[];
  onClick?: Function;
  detailUrl?: string;
};

const OtherRatesRow = ({
  detailUrl = undefined,
  i18n,
  onClick = undefined,
  otherRates = undefined,
}: OtherRatesRowProps) => {
  const displayNum =
    otherRates && otherRates.length > MAX_NUM_THRESHOLD ? 3 : 4;
  const popularRates = otherRates?.slice(0, displayNum);
  const normalRates = otherRates?.slice(displayNum);

  const lessNum = otherRates?.length;

  return (
    <div className={STYLES.OtherRatesRow}>
      {popularRates?.map((rate) => (
        <Rate
          i18n={i18n}
          rate={rate}
          detailUrl={detailUrl}
          onClick={onClick}
          key={rate.partnerId}
          lessNum={lessNum}
        />
      ))}
      {normalRates && normalRates.length > 0 && (
        <NormalRates
          rates={normalRates}
          detailUrl={detailUrl}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default withI18n(OtherRatesRow);
