import type { I18n } from 'common-types/types/hotels-components/types';

type Props = {
  i18n: I18n;
  timeRemain: any;
};

const ONE_MINUTE = 1000 * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;

const getTimeString = ({ i18n, timeRemain = 0 }: Props): string => {
  const { translate } = i18n;
  let timeString = '';

  let days = Math.floor(timeRemain / ONE_DAY);
  let hours = Math.floor((timeRemain / ONE_HOUR) % 24);
  const minutes = Math.ceil((timeRemain / ONE_MINUTE) % 60);

  if (timeRemain <= 0) {
    return '';
  }

  if (days) {
    hours = Math.ceil((timeRemain / ONE_HOUR) % 24);
    if (hours === 0) {
      timeString = translate(
        `DirectDiscount_days${days > 1 ? 'Other' : '1'}`,
        { number: days },
        '@@',
        '@@',
      );
    } else if (hours === 24) {
      days += 1;
      timeString = translate(
        `DirectDiscount_days${days > 1 ? 'Other' : '1'}`,
        { number: days },
        '@@',
        '@@',
      );
    } else {
      timeString = translate(
        `DirectDiscount_banner_time_day${days > 1 ? 's' : ''}_hour${
          hours > 1 ? 's' : ''
        }`,
        {
          day: days,
          hour: hours > 1 ? hours : 1,
        },
      );
    }
  } else if (hours) {
    if (minutes === 0) {
      timeString = translate(
        `DirectDiscount_banner_hour${hours > 1 ? 's' : ''}`,
        { hours },
        '@@',
        '@@',
      );
    } else if (minutes === 60) {
      hours += 1;
      days += 1;
      if (hours > 23) {
        timeString = translate(
          `DirectDiscount_days${days > 1 ? 'Other' : '1'}`,
          { number: days },
          '@@',
          '@@',
        );
      } else {
        timeString = translate(
          `DirectDiscount_banner_hour${hours > 1 ? 's' : ''}`,
          { hours },
          '@@',
          '@@',
        );
      }
    } else {
      timeString = translate(
        `DirectDiscount_banner_time_hour${hours > 1 ? 's' : ''}_minute${
          minutes > 1 ? 's' : ''
        }`,
        {
          hour: hours,
          minute: minutes > 1 ? minutes : 1,
        },
      );
    }
  } else if (minutes > 59) {
    hours += 1;
    timeString = translate(
      `DirectDiscount_banner_hour${hours > 1 ? 's' : ''}`,
      { hours },
      '@@',
      '@@',
    );
  } else if (minutes > 1) {
    timeString = translate(`DirectDiscount_banner_time_minutes`, {
      minutes,
    });
  } else if (minutes > 0) {
    timeString = translate('DirectDiscount_banner_time_minute');
  } else {
    timeString = '';
  }

  return Array.isArray(timeString) ? timeString.join('') : timeString;
};

export default getTimeString;
export { ONE_MINUTE, ONE_HOUR, ONE_DAY };
