import classnames from 'classnames';

import BpkRating, {
  RATING_SIZES,
} from '@skyscanner/backpack-web/bpk-component-rating';

import { RATING_KEY_MAP } from '../../hotels-legacy/client/src/components/rating-key-map';
import { scoreSummaryForReview } from '../../hotels-legacy/client/src/services/score';
import { withI18n } from '../../hotels-legacy/client/src/skyscanner-application/i18n';
import {
  getId,
  getAriaHidden,
} from '../../hotels-legacy/client/src/utils/a11y';

import type { I18n } from 'common-types/types/hotels-components/types';

import STYLES from './ReviewRating.module.scss';

type ReviewRatingProps = {
  i18n: I18n;
  className?: string;
  score: number;
  count?: number;
  image?: string;
  vertical?: boolean;
  index?: number;
};

const ReviewRating = ({
  className = undefined,
  count = undefined,
  i18n,
  image = undefined,
  index = undefined,
  score,
  vertical = false,
}: ReviewRatingProps) => {
  if (!score || !count) {
    return null;
  }

  const formatScore = i18n.formatNumber(score, 1);
  const formatCount = i18n.formatNumber(count, 0);
  const ratingKey = scoreSummaryForReview(score);

  let ariaLabel = image
    ? i18n.translate('aria_trip_advisor_review_rating', {
        score,
        count,
      })
    : i18n.translate('aria_trip_com_review_rating', {
        desc: ratingKey && i18n.translate(RATING_KEY_MAP[ratingKey]),
        score,
        count,
      });

  ariaLabel = Array.isArray(ariaLabel) ? ariaLabel.join('') : '';

  const title = image ? (
    <img
      src={image}
      alt={score.toString()}
      className={STYLES.ReviewRating__image}
    />
  ) : (
    ratingKey && i18n.translate(RATING_KEY_MAP[ratingKey])
  );

  const subtitle: string =
    count > 9
      ? i18n.translate('Reviews_label_X', {
          0: formatCount,
        })
      : i18n.translatePlural('Reviews_label_', count, 'number', {
          0: formatCount,
        });

  return (
    <BpkRating
      className={classnames(STYLES.ReviewRating, className)}
      id={getId('review_rating', index)}
      ariaLabel={ariaLabel}
      aria-hidden={getAriaHidden(index)}
      value={formatScore}
      title={title}
      subtitle={Array.isArray(subtitle) ? subtitle.join('') : ''}
      showScale={!image}
      size={vertical ? RATING_SIZES.large : RATING_SIZES.base}
    />
  );
};

export default withI18n(ReviewRating);
