import { withI18n } from '../../hotels-legacy/client/src/skyscanner-application/i18n';
import { getPricePolicyText } from '../../hotels-legacy/common/src/services/price-policy';

import type {
  HotelPricePolicy as HotelPricePolicyValue,
  I18n,
} from 'common-types/types/hotels-components/types';

type HotelPricePolicyProps = {
  i18n: I18n;
  pricePolicy: HotelPricePolicyValue;
};

const HotelPricePolicy = ({ i18n, pricePolicy }: HotelPricePolicyProps) => (
  <span>{getPricePolicyText(pricePolicy, i18n)}</span>
);

export default withI18n(HotelPricePolicy);
