import entityTypes from '../../hotels-legacy/client/src/components/entity-types';
import {
  withI18n,
  FormattedMessage,
} from '../../hotels-legacy/client/src/skyscanner-application/i18n';

import type { MappingShape } from '../../hotels-legacy/client/src/components/types';
import type {
  Distance as DistanceI,
  I18n,
} from 'common-types/types/hotels-components/types';

type Props = {
  i18n: I18n;
  distance: DistanceI;
  isMobile?: boolean;
};

type FormattedMessageProps = {
  span: boolean;
  label: string;
  data: MappingShape;
};

const Distance = ({
  distance: {
    cityName,
    distanceMeters,
    referenceEntityName,
    referenceEntityType,
  },
  i18n,
  isMobile = false,
}: Props) => {
  if (referenceEntityType === entityTypes.HOTEL) {
    return i18n
      .formatDistance(distanceMeters, {
        kmString: 'Distance_label_fromHotelKm',
        milesString: 'Distance_label_fromHotelMiles',
      })
      .map((text: any, i: number) => <span key={text}>{text}</span>);
  }

  const props = { span: true };
  const formattedDistance = i18n.formatDistance(distanceMeters);

  if (referenceEntityType === entityTypes.CITY) {
    return (
      <FormattedMessage
        label="Distance_label_fromCityCentre"
        data={{
          distance: formattedDistance,
        }}
        {...props}
      />
    );
  }

  if (referenceEntityType === entityTypes.NAMED_ENTITY) {
    if (
      (isMobile && referenceEntityName!.length > 30) ||
      referenceEntityName!.length > 40
    ) {
      Object.assign(props, {
        label: 'Distance_label_fromPlaceMore',
        data: {
          0: formattedDistance,
        },
      });
    } else {
      Object.assign(props, {
        label: 'Distance_label_fromPlace',
        data: {
          distance: formattedDistance,
          place: referenceEntityName,
        },
      });
    }
    return <FormattedMessage {...(props as FormattedMessageProps)} />;
  }

  return cityName;
};

export default withI18n(Distance);
