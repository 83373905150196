type HotelCard = {
  id: string;
};

type LandingPageDataStoreState = {
  hotelSearchResults: {
    hotelCards: HotelCard[];
    loading: boolean;
  };
  poiSearchResults: {
    attractions?: any;
    restaurants?: any;
  };
};

export type LandingPageDataStoreResult = {
  setHotelSearchResults: Function;
  startHotelSearchCycle: Function;
  getHotelSearchResults: Function;
  getHotelCards: Function;
  getPOISearchResults: Function;
  addListener: Function;
  removeListener: Function;
  getAttractions: Function;
  getRestaurants: Function;
};

const NUMBER_OF_HOTELS = 9;

const LandingPageDataStore = ({ backendGateway }: any) => {
  const state: LandingPageDataStoreState = {
    hotelSearchResults: {
      hotelCards: [],
      loading: true,
    },
    poiSearchResults: {},
  };

  let callbackList: Function[] = [];

  const addListener = (fun: Function) => {
    callbackList.push(fun);
    fun(state);
  };

  const removeListener = (fun: Function) => {
    callbackList = callbackList.filter((cb) => cb !== fun);
  };

  const updateListeners = () => {
    callbackList.forEach((fun) => fun(state));
  };

  const setHotelSearchResults = (data: any) => {
    state.hotelSearchResults = { ...data };
    updateListeners();
  };

  const setLoadingState = (isLoading: boolean) => {
    state.hotelSearchResults.loading = isLoading;
    updateListeners();
  };

  const getHotelSearchResults = () => state.hotelSearchResults;

  const getHotelCards = () => state.hotelSearchResults.hotelCards;

  const startHotelSearchCycle = ({ entityId, filters, stay }: any) => {
    const queryParameters = {
      stay,
      entityId,
      offset: 0,
      count: NUMBER_OF_HOTELS,
      filters,
    };

    backendGateway.startSearchUpdates(
      queryParameters,
      (err: any, data: any) => {
        const { hotelSearchResults: currentHotelSearchResults } = state;

        if (err) {
          setLoadingState(false);
          return;
        }

        const { hotelCards } = data;
        const uniqueHotelCards = currentHotelSearchResults.hotelCards.filter(
          (existingHotel) =>
            !hotelCards.find(
              (newHotel: any) => newHotel.id === existingHotel.id,
            ),
        );

        hotelCards.push(...uniqueHotelCards);
        setHotelSearchResults(data);
      },
      true,
    );
  };

  const getPOISearchResults = () => state.poiSearchResults;

  const getAttractions = () => state.poiSearchResults.attractions;

  const getRestaurants = () => state.poiSearchResults.restaurants;

  return {
    setHotelSearchResults,
    startHotelSearchCycle,
    getHotelSearchResults,
    getHotelCards,
    getPOISearchResults,
    addListener,
    removeListener,
    getAttractions,
    getRestaurants,
  };
};

export default LandingPageDataStore;
