import type { ReactNode } from 'react';

import classnames from 'classnames';

import {
  getIds,
  getId,
  getAriaHidden,
} from '../../../hotels-legacy/client/src/utils/a11y';
import { ParagraphSm } from '../../HotelsCommon/Text';

import STYLES from './BasicInfo.module.scss';

type Props = {
  ratingNode?: ReactNode;
  confidentMsg?: ReactNode;
  distanceNode?: ReactNode;
  hotelCardAccessibility?: boolean;
  index?: number;
};

const BasicInfo = ({
  confidentMsg = undefined,
  distanceNode = undefined,
  hotelCardAccessibility = false,
  index = undefined,
  ratingNode = undefined,
}: Props) => {
  if (!ratingNode && !confidentMsg && !distanceNode) {
    return null;
  }
  const ratingOrConfigdentMsg = ratingNode || confidentMsg;
  return (
    <div
      className={classnames({
        [STYLES.BasicInfo__space]: !hotelCardAccessibility,
      })}
      aria-labelledby={getIds(['review_rating', 'confident_message'], index)}
    >
      {ratingOrConfigdentMsg && (
        <div className={STYLES.BasicInfo__ratingAndConfidentMsg}>
          {ratingNode}
          {confidentMsg}
        </div>
      )}
      {distanceNode && (
        <ParagraphSm
          className={classnames(STYLES.BasicInfo__distance, {
            [STYLES.BasicInfo__gap]: ratingOrConfigdentMsg && distanceNode,
            [STYLES['BasicInfo__distance--accessibility']]:
              hotelCardAccessibility,
          })}
          id={getId('distance', index)}
          aria-hidden={getAriaHidden(index)}
        >
          {distanceNode}
        </ParagraphSm>
      )}
    </div>
  );
};

export default BasicInfo;
