import { forwardRef } from 'react';
import type { ReactElement, ForwardedRef } from 'react';

import { withI18n } from '../../skyscanner-application/i18n';

import type { I18n } from 'common-types/types/hotels-components/types';

type BookOnWebsiteProps = {
  i18n: I18n;
  className?: string;
};

const BookOnWebsite = (
  { className = undefined, i18n }: BookOnWebsiteProps,
  ref: ForwardedRef<HTMLDivElement>,
): ReactElement => (
  <div className={className} ref={ref}>
    {i18n.translate('book_on_hotelSite_label')}
  </div>
);

export default withI18n(forwardRef(BookOnWebsite));
