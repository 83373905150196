import type { AmenityShape } from '../../hotels-legacy/client/src/components/types';

const AMENITY_POSITIVE = [
  'free_cancellation',
  'refundable',
  'breakfast_included',
  'half_board',
  'full_board',
  'all_inclusive',
  'RoomRates_option_payOnArrival',
  'Filters_label_POA',
];

const getPositiveAmenities = (amenities: AmenityShape[] = []) =>
  amenities.filter((amenity) => AMENITY_POSITIVE.includes(amenity.name));

export default getPositiveAmenities;
