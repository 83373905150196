import { Component, createRef } from 'react';
import type { RefObject } from 'react';

import BpkBadge, {
  BADGE_TYPES,
} from '@skyscanner/backpack-web/bpk-component-badge';
import BpkPriceTag from '@skyscanner/backpack-web/bpk-component-icon/sm/price-tag';
import BpkPopover from '@skyscanner/backpack-web/bpk-component-popover';

import DirectDiscountModal from '../../screens/DbookFunnelPage/modals/DirectDiscountModal';
import { withConfiguration } from '../../skyscanner-application/configuration';
import { withI18n } from '../../skyscanner-application/i18n';
import { getId, getAriaHidden } from '../../utils/a11y';

import withTimeRemain from './withTimeRemain';

import type {
  Configuration,
  GeneralCoupon,
  I18n,
} from 'common-types/types/hotels-components/types';

import STYLES from './DirectDiscount.module.scss';

type Props = {
  i18n: I18n;
  configs: Configuration;
  timeRemainString: string;
  partnerName?: string;
  coupon: GeneralCoupon;
  index?: number;
};

type State = {
  isModalOpen: boolean;
  isTooltipOpen: boolean;
};

const defaultProps = {
  partnerName: undefined,
  index: undefined,
};

class DirectDiscount extends Component<Props, State> {
  static defaultProps = defaultProps;

  tipShowable: boolean;

  target: RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.tipShowable = !!props.partnerName;

    this.state = {
      isModalOpen: false,
      isTooltipOpen: false,
    };
    this.target = createRef();
  }

  setTooltip(isTooltipOpen: boolean) {
    if (!this.tipShowable) {
      return;
    }
    this.setState({
      isTooltipOpen,
    });
  }

  setModal(isModalOpen: boolean) {
    if (!this.tipShowable) {
      return;
    }
    this.setState({
      isModalOpen,
    });
  }

  render() {
    const { configs, coupon, i18n, index, partnerName, timeRemainString } =
      this.props;
    const { isModalOpen, isTooltipOpen } = this.state;

    const secretBadge = configs && configs.secretDealsEnabled;

    return (
      <>
        {secretBadge ? (
          <BpkBadge type={BADGE_TYPES.strong}>
            {i18n.translate('HotelCard_label_savePercentage', {
              pct_saving: coupon.discountPercentage,
            })}
          </BpkBadge>
        ) : (
          <>
            <BpkPopover
              placement="top"
              closeButtonText={i18n.translate('Modal_close')}
              portalClassName={STYLES.DirectDiscount__popoverFooter}
              target={
                <span ref={this.target}>
                  <BpkBadge
                    id={getId('direct_discount', index)}
                    aria-label={i18n.translate(
                      'aria_discount',
                      {
                        discount: coupon.discountPercentage,
                      },
                      '{',
                      '}',
                      true,
                    )}
                    aria-hidden={getAriaHidden(index)}
                    className={STYLES.DirectDiscount__content}
                    type={BADGE_TYPES.strong}
                    onTouchStart={() => {
                      this.setModal(true);
                    }}
                    onMouseEnter={() => {
                      this.setTooltip(true);
                    }}
                    onMouseLeave={() => {
                      this.setTooltip(false);
                    }}
                  >
                    <BpkPriceTag />
                    {i18n.translate('HotelCard_label_savePercentage', {
                      pct_saving: coupon.discountPercentage,
                    })}
                  </BpkBadge>
                </span>
              }
              onClose={() => {
                this.setTooltip(false);
              }}
              isOpen={isTooltipOpen}
            >
              {i18n.translate('DirectDiscount_badge_tooltip', {
                partnerName,
                discount: coupon.discountPercentage,
                time: timeRemainString,
              })}
            </BpkPopover>
            <DirectDiscountModal
              isOpen={isModalOpen}
              onClick={() => {
                this.setModal(false);
              }}
              header={i18n.translate(
                'DirectDiscount_badge_tooltipModal_header',
                {
                  discount: coupon.discountPercentage,
                },
              )}
              content={i18n.translate(
                'DirectDiscount_badge_tooltipModal_content',
                {
                  partnerName,
                  time: timeRemainString,
                },
              )}
            />
          </>
        )}
      </>
    );
  }
}

// @ts-ignore
export default withConfiguration(withI18n(withTimeRemain(DirectDiscount)));
