import { Component, createRef } from 'react';
import type { LegacyRef } from 'react';

import ArrowIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/arrow-down';
import NewWindowIconSm from '@skyscanner/backpack-web/bpk-component-icon/sm/new-window';
import { BpkButtonLink } from '@skyscanner/backpack-web/bpk-component-link';
import BpkLink from '@skyscanner/backpack-web/bpk-component-link/src/BpkLink';
import BpkPopover from '@skyscanner/backpack-web/bpk-component-popover';

import { withI18n } from '../../../hotels-legacy/client/src/skyscanner-application/i18n';
import { InlineTextLabel1, InlineTextXs } from '../../HotelsCommon/Text';

import type { I18n, Price } from 'common-types/types/hotels-components/types';

import STYLES from './NormalRates.module.scss';

type RateProps = {
  i18n: I18n;
  rate?: Price;
  onClick?: Function;
  detailUrl?: string;
};

const Rate = ({
  detailUrl = undefined,
  i18n,
  onClick = undefined,
  rate = undefined,
}: RateProps) => {
  const url = rate?.funnelType === 'dbook' ? detailUrl : rate?.deepLink;

  const partnerName = () =>
    rate?.funnelType === 'meta' ? (
      <div>
        <InlineTextXs className={STYLES.Rate__nameEx}>{rate.name}</InlineTextXs>
        <NewWindowIconSm className={STYLES.Rate__newWindowIconSmall} />
      </div>
    ) : (
      <InlineTextXs className={STYLES.Rate__name}>{rate?.name}</InlineTextXs>
    );

  return (
    <div className={STYLES.NormalRates}>
      <BpkLink
        className={STYLES.Rate}
        href={url}
        blank
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          onClick?.(false, rate, rate?.funnelType === 'dbook', 'other');
        }}
      >
        {partnerName()}
        <InlineTextLabel1>
          {rate && i18n.formatCurrency(rate.price)}
        </InlineTextLabel1>
      </BpkLink>
    </div>
  );
};

type NormalRatesProps = {
  i18n: I18n;
  rates: Price[];
  onClick?: Function;
  detailUrl?: string;
};

type NormalRatesState = {
  isOpen: boolean;
};

class NormalRates extends Component<NormalRatesProps, NormalRatesState> {
  static defaultProps = {
    onClick: undefined,
    detailUrl: undefined,
  };

  private target: LegacyRef<HTMLSpanElement> | undefined;

  constructor(props: NormalRatesProps) {
    super(props);

    this.state = {
      isOpen: false,
    };
    this.target = createRef();
  }

  openPopover = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      isOpen: true,
    });
  };

  closePopover = () => {
    this.setState({
      isOpen: false,
    });
  };

  render() {
    const { detailUrl, i18n, onClick, rates } = this.props;

    return (
      <div className={STYLES.NormalRates}>
        <BpkPopover
          id="normal-rate-popover"
          target={
            <span ref={this.target}>
              <BpkButtonLink
                onClick={this.openPopover}
                className={STYLES.BtnLinkRow}
              >
                {i18n.translate('Button_seeMore')}
                <ArrowIcon className={STYLES.NormalRates__arrowIcon} />
              </BpkButtonLink>
            </span>
          }
          onClose={this.closePopover}
          isOpen={this.state.isOpen}
          label=""
          closeButtonText={i18n.translate('Modal_close')}
        >
          {rates.map((rate) => (
            <Rate
              i18n={i18n}
              rate={rate}
              onClick={onClick}
              detailUrl={detailUrl}
              key={rate.partnerId}
            />
          ))}
        </BpkPopover>
      </div>
    );
  }
}

export default withI18n(NormalRates);
