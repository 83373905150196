import queryString from 'query-string';
import { getUtid, getCsrfToken } from 'saddlebag-user-management';

import { DATE_FORMAT } from '../skyscanner-application/i18n';

import { getCashBackUserId } from './cash-back-user-id';
import logger from './logger';
import {
  X_GATEWAY_SERVED_BY,
  getXGatewayServedBy,
  storeXGatewayServedBy,
} from './x-gateway-servedby';

const StandardURL = (address: any) => new URL(address);
const now = () => new Date();

const getQueryParamStr = (obj: any) => queryString.stringify(obj);

const BackendGateway = ({
  backendUrl,
  eventSource,
  i18n,
  userContext,
}: {
  backendUrl: any;
  eventSource: any;
  i18n: any;
  userContext: any;
}) => {
  let xGatewayServedBy = getXGatewayServedBy();

  const getCommonOpts = (options?: any, csrfToken = false) => {
    if (!xGatewayServedBy && !csrfToken) {
      return options;
    }

    const finalHeaders: any = {};
    const { headers = {}, ...rest } = options || {};

    if (csrfToken) {
      finalHeaders['x-csrf-token'] = getCsrfToken();
    }
    if (xGatewayServedBy) {
      finalHeaders[X_GATEWAY_SERVED_BY] = xGatewayServedBy;
    }

    return { headers: { ...finalHeaders, ...headers }, ...rest };
  };

  const builtInFetch = async (url: any, options?: any, csrfToken?: any) => {
    const res = await fetch(url, getCommonOpts(options, csrfToken));
    xGatewayServedBy = res.headers.get(X_GATEWAY_SERVED_BY) || '';
    storeXGatewayServedBy(xGatewayServedBy);
    return res;
  };

  const getBackendUrl = (path: any, queryParams: any = {}) => {
    const { userPreferences, ...restParams } = queryParams;
    if (userPreferences) {
      Object.assign(userContext.userPreferences, userPreferences);
    }

    const params = userContext
      ? {
          ...restParams,
          user_context: JSON.stringify(userContext),
        }
      : restParams;

    return StandardURL(`${backendUrl}${path}?${getQueryParamStr(params)}`).href;
  };

  const getCommonUrlParams = (stay: any) => {
    const { culture, formatDate } = i18n;
    const { childrenAges } = stay;

    return {
      market: culture.market,
      currency: culture.currency,
      locale: culture.locale,
      checkin: formatDate(stay.checkIn, DATE_FORMAT.NON_LOCALISED_SHORT),
      checkout: formatDate(stay.checkOut, DATE_FORMAT.NON_LOCALISED_SHORT),
      adults: stay.numberOfAdults,
      rooms: stay.numberOfRooms,
      children_ages:
        childrenAges && childrenAges.length
          ? childrenAges.join(',')
          : undefined,
    };
  };

  const getSearchUrlParams = (
    entityId: any,
    skyscannerNodeCode: any,
    stay: any,
    offset: any,
    count: any,
  ) => ({
    ...getCommonUrlParams(stay),
    entity_id: entityId,
    skyscanner_node_code: skyscannerNodeCode,
    offset,
    count,
  });

  const getSearchUrl = (params: any) => getBackendUrl('/search/v2', params);

  const getStaticSearchUrl = (params: any) =>
    getBackendUrl('/search/v2/static', params);

  const getRecommendationUrlParams = (
    entityId: any,
    skyscannerNodeCode: any,
    stay: any,
    searchCycleId: any,
  ) => ({
    ...getCommonUrlParams(stay),
    entity_id: entityId,
    skyscanner_node_code: skyscannerNodeCode,
    search_id: searchCycleId,
  });

  const getPricesUrl = (
    hotelId: any,
    searchEntityId: any,
    stay: any,
    searchCycleId: any,
    minPriceRoomId: any,
    ignoreCache: any,
    priceType: any,
    userPreferences: any,
    filters: any,
    source: any,
    traceInfo: any,
    audienceId: any,
  ) => {
    const params = {
      ...getCommonUrlParams(stay),
      entity_id: searchEntityId,
      search_cycle_id: searchCycleId,
      min_price_room_id: minPriceRoomId,
      ignore_cache: ignoreCache,
      price_type: priceType,
      userPreferences,
      filters: JSON.stringify(filters),
      from_cash_back: !!getCashBackUserId(),
      source,
      trace_info: traceInfo,
      audience_id: audienceId,
    };

    // after changing the stay conditions, the old searchCycleId needs to be removed
    // to avoid getting the previous prices due to the cache
    if (userContext && !searchCycleId) {
      // eslint-disable-next-line no-param-reassign
      delete userContext.searchCycleId;
    }

    if (userContext && userContext.verifyAnonymousJwtEnable) {
      return getBackendUrl(`/prices/v3/${hotelId}`, params);
    }

    return getBackendUrl(`/prices/v2/${hotelId}`, params);
  };

  const getSimilarHotelsUrl = (hotelId: any, stay: any, priceType: any) => {
    const params = {
      ...getCommonUrlParams(stay),
      price_type: priceType,
    };
    return getBackendUrl(`/similar-hotels/v1/${hotelId}`, params);
  };

  return {
    startSearchUpdates: (
      {
        audienceId,
        bounds = {},
        count,
        entityId,
        filters,
        hpaVerification,
        offset,
        priceType,
        skyscannerNodeCode,
        sort,
        stay,
        top,
        traceInfo,
        upsortHotels,
        userPreferences,
        ...rest
      }: {
        stay?: any;
        entityId?: any;
        skyscannerNodeCode?: any;
        offset?: any;
        count?: any;
        sort?: any;
        filters?: any;
        top?: any;
        priceType?: any;
        userPreferences?: any;
        bounds?: any;
        upsortHotels?: any;
        traceInfo?: any;
        audienceId?: any;
        hpaVerification?: any;
        rest?: any;
      },
      cb: any,
      enableCache?: any,
    ) => {
      const params = getSearchUrlParams(
        entityId,
        skyscannerNodeCode,
        stay,
        offset,
        count,
      );
      const searchParams = {
        ...rest,
        ...params,
        sort,
        top,
        filters: JSON.stringify(filters),
        price_type: priceType,
        sw_lat: bounds.south,
        sw_lng: bounds.west,
        ne_lat: bounds.north,
        ne_lng: bounds.east,
        upsort_hotels: upsortHotels,
        trace_info: traceInfo,
        audience_id: audienceId,
        hpa_verification: hpaVerification,
        from_cash_back: !!getCashBackUserId(),
      };

      if (enableCache) {
        const url = getStaticSearchUrl(searchParams);
        return eventSource.start(url, 'staticSearchState', cb, getCommonOpts());
      }
      const url = getSearchUrl({ ...searchParams, userPreferences });
      return eventSource.start(url, 'searchState', cb, getCommonOpts());
    },

    recommendationResults: async (
      {
        count,
        entityId,
        filters,
        offset,
        priceType,
        skyscannerNodeCode,
        sort,
        stay,
        top,
      }: {
        count: any;
        entityId: any;
        filters: any;
        offset: any;
        priceType: any;
        skyscannerNodeCode: any;
        sort: any;
        stay: any;
        top: any;
      },
      searchCycleId: any,
      recommendTypes: any,
    ) => {
      const params = getRecommendationUrlParams(
        entityId,
        skyscannerNodeCode,
        stay,
        searchCycleId,
      );
      const recommendParams = {
        ...params,
        offset,
        count,
        sort,
        top,
        filters: JSON.stringify(filters),
        price_type: priceType,
        recommend_types: recommendTypes,
      };
      const url = getBackendUrl('/hotels-recommendation/v1', recommendParams);
      const res = await builtInFetch(url);
      return res.json();
    },

    startRecommendationUpdates: (
      {
        count,
        entityId,
        filters,
        offset,
        priceType,
        recommendTypes,
        searchCycleId,
        skyscannerNodeCode,
        sort,
        stay,
        top,
      }: {
        count: any;
        entityId: any;
        filters: any;
        offset: any;
        priceType: any;
        recommendTypes: any;
        searchCycleId: any;
        skyscannerNodeCode: any;
        sort: any;
        stay: any;
        top: any;
      },
      cb: any,
    ) => {
      const params = getRecommendationUrlParams(
        entityId,
        skyscannerNodeCode,
        stay,
        searchCycleId,
      );
      const recommendParams = {
        ...params,
        offset,
        count,
        sort,
        top,
        filters: JSON.stringify(filters),
        price_type: priceType,
        recommend_types: recommendTypes,
      };

      const url = getBackendUrl('/hotels-recommendation/v2', recommendParams);
      return eventSource.start(
        url,
        'recommendHotelsState',
        cb,
        getCommonOpts(),
      );
    },

    startMapUpdates: (
      {
        bounds,
        count = 30,
        entityId,
        filters,
        skyscannerNodeCode,
        stay,
        ...rest
      }: {
        bounds?: any;
        count?: any;
        entityId?: any;
        filters?: any;
        skyscannerNodeCode?: any;
        stay: any;
        rest?: any;
      },
      cb: any,
    ) => {
      const offset = 0;

      const params = getSearchUrlParams(
        entityId,
        skyscannerNodeCode,
        stay,
        offset,
        count,
      );
      const mapParams = {
        ...params,
        ...rest,
        sw_lat: bounds.south,
        sw_lng: bounds.west,
        ne_lat: bounds.north,
        ne_lng: bounds.east,
        filters: JSON.stringify(filters),
        from_cash_back: !!getCashBackUserId(),
      };

      const url = getBackendUrl(`/search/v2`, mapParams);
      return eventSource.start(url, 'searchState', cb, getCommonOpts());
    },

    startPricesUpdates: (
      {
        audienceId,
        filters,
        hotelId,
        ignoreCache,
        minPriceRoomId,
        priceType,
        searchCycleId,
        searchEntityId,
        source,
        stay,
        traceInfo,
        userPreferences,
      }: {
        audienceId?: any;
        filters?: any;
        hotelId?: any;
        ignoreCache?: any;
        minPriceRoomId?: any;
        priceType?: any;
        searchCycleId?: any;
        searchEntityId?: any;
        source?: any;
        stay?: any;
        traceInfo?: any;
        userPreferences?: any;
      },
      cb: any,
    ) => {
      const url = getPricesUrl(
        hotelId,
        searchEntityId,
        stay,
        searchCycleId,
        minPriceRoomId,
        ignoreCache,
        priceType,
        userPreferences,
        filters,
        source,
        traceInfo,
        audienceId,
      );

      return eventSource.start(
        url,
        'pricesState',
        cb,
        getCommonOpts(
          {},
          !!(userContext && userContext.verifyAnonymousJwtEnable),
        ),
      );
    },

    startSimilarHotelsUpdates: (
      {
        hotelId,
        priceType,
        stay,
      }: { hotelId?: any; priceType?: any; stay?: any },
      cb: any,
    ) => {
      const url = getSimilarHotelsUrl(hotelId, stay, priceType);
      return eventSource.start(url, 'similarHotelsState', cb, getCommonOpts());
    },

    nearbyPoiResultV2: async ({
      entityId,
      lat,
      lon,
      maxDistance,
      minDistance,
      poiNumber,
    }: {
      entityId: any;
      lat: any;
      lon: any;
      maxDistance: any;
      minDistance: any;
      poiNumber: any;
    }) => {
      const url = getBackendUrl('/pois/v2/nearby', {
        lat,
        lon,
        minDistance,
        maxDistance,
        poiNumber,
        entityId,
      });
      const res = await builtInFetch(url);
      return res.json();
    },

    nearbyTransport: async ({
      cityId,
      lat,
      limit,
      lon,
    }: {
      cityId: any;
      lat: any;
      limit: any;
      lon: any;
    }) => {
      const url = getBackendUrl('/nearby-transport/v1', {
        lat,
        lon,
        limit,
        cityId,
      });
      const res = await builtInFetch(url);
      return res.json();
    },

    flexibleDatesResult: async ({
      entityIds,
      stay,
    }: {
      entityIds: any;
      stay: any;
    }) => {
      const { culture } = i18n;
      const { adults, end, rooms, start } = stay;
      const params = {
        market: culture.market,
        currency: culture.currency,
        locale: culture.locale,
        entityIds,
        adults,
        rooms,
        start,
        end,
      };
      const url = getBackendUrl('/flexible-dates/v1', params);
      const res = await builtInFetch(url);
      return res.json();
    },

    trimUrl: (src: any) => getBackendUrl('/images/v1/trim', { url: src }),

    realReviews: async ({
      hotelId,
      ...params
    }: {
      hotelId: any;
      [key: string]: any;
    }) => {
      const url = getBackendUrl(`/reviews/v3/${hotelId}`, {
        locale: i18n.culture.locale,
        ...params,
      });
      const res = await builtInFetch(url);
      return res.json();
    },

    translateReview: async ({
      partnerId,
      reviewId,
      sourceLocale,
    }: {
      partnerId: any;
      reviewId: any;
      sourceLocale: any;
    }) => {
      const url = getBackendUrl(
        `/reviews/v2/translate/${reviewId}/${partnerId}/${sourceLocale}/${i18n.culture.locale}`,
      );
      const res = await builtInFetch(url);
      return res.json();
    },

    validateEmail: async (email: any) => {
      logger.event(`email domain: ${email.split('@')[1]}`);
      const url = getBackendUrl(`/validate-email/v1`);
      const res = await builtInFetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      });
      return res.json();
    },

    translation: async ({ ...params }) => {
      const url = getBackendUrl(`/translation/v1`, {
        targetLocale: i18n.culture.locale,
        ...params,
      });
      const res = await builtInFetch(url);
      return res.json();
    },

    reviewHelpful: async ({
      hotelId,
      isHelpful,
      locale,
      partner,
      partnerReviewId,
    }: {
      hotelId: any;
      isHelpful: any;
      locale: any;
      partner: any;
      partnerReviewId: any;
    }) => {
      const url = getBackendUrl('/reviews/v1/useful');
      const res = await builtInFetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          partner,
          locale,
          hotel_id: hotelId,
          partner_review_id: partnerReviewId,
          operation_type: isHelpful ? 1 : 0,
        }),
      });
      return res.json();
    },

    policyTranslation: async ({ ...params }) => {
      const url = getBackendUrl(`/v2/policy/translate`, {
        targetLocale: i18n.culture.locale,
        ...params,
      });
      const res = await builtInFetch(url);
      return res.json();
    },

    getHotelStatic: async ({
      hotelId,
      onlyImages = false,
    }: {
      hotelId: any;
      onlyImages: any;
    }) => {
      const url = getBackendUrl(
        `/hotel-static/v1/${i18n.culture.locale}/${i18n.culture.market}/${i18n.culture.currency}/${hotelId}`,
        { onlyImages },
      );
      const res = await builtInFetch(url);
      return res.json();
    },

    createDiscount: async ({
      campaignId,
      culture,
      entityId,
      offerCode,
      shouldUpdateUserPreferences = false,
      source,
      stay,
      trackInfo,
    }: {
      campaignId?: any;
      culture?: any;
      entityId?: any;
      offerCode?: any;
      shouldUpdateUserPreferences?: any;
      source?: any;
      stay?: any;
      trackInfo?: any;
    }) => {
      const params = {};
      if (shouldUpdateUserPreferences) {
        Object.assign(params, {
          userPreferences: {
            utid: getUtid(),
            isLoggedIn: true,
          },
        });
      }
      const url = getBackendUrl(`/v1/discount/entity/${entityId}`, {
        entityId,
        offerCode,
        campaignId,
        source,
        locale: culture.locale,
        market: culture.market,
        currency: culture.currency,
        adults: stay.numberOfAdults,
        rooms: stay.numberOfRooms,
        checkIn: i18n.formatDate(stay.checkIn, DATE_FORMAT.NON_LOCALISED_SHORT),
        checkOut: i18n.formatDate(
          stay.checkOut,
          DATE_FORMAT.NON_LOCALISED_SHORT,
        ),
        bookingDate: i18n.formatDate(now(), DATE_FORMAT.NON_LOCALISED_SHORT),
        trackInfo: JSON.stringify(trackInfo),
        ...params,
      });
      const res = await builtInFetch(url);
      return res.json();
    },

    deleteHistoryHotels: async ({ histories }: { histories: any }) => {
      const url = getBackendUrl(`/viewed-hotels/v1/delete-hotels`);
      const res = await builtInFetch(
        url,
        {
          method: 'POST',
          body: JSON.stringify({
            all: histories && histories.length === 0,
            histories,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        true,
      );
      return res.json();
    },

    searchViewedHotels: async ({
      priceType,
      stay,
    }: {
      priceType: any;
      stay: any;
    }) => {
      const params = getCommonUrlParams(stay);
      const url = getBackendUrl('/viewed-hotels/v1/search-viewed-hotels', {
        ...params,
        price_type: priceType,
      });
      const response = await builtInFetch(url, {}, true);
      return response.json();
    },

    startSearchViewedHotels: (
      { priceType, stay }: { priceType: any; stay: any },
      cb: any,
    ) => {
      const params = getCommonUrlParams(stay);
      const url = getBackendUrl('/viewed-hotels/v1/get-hotels', {
        ...params,
        price_type: priceType,
      });

      return eventSource.start(
        url,
        'getViewedHotelsState',
        cb,
        getCommonOpts({}, true),
      );
    },

    saveViewedHotels: async ({
      cityId,
      destinationId,
      historyPrice,
      hotelId,
    }: {
      cityId: any;
      destinationId: any;
      historyPrice: any;
      hotelId: any;
    }) => {
      const url = getBackendUrl(`/viewed-hotels/v1/save-hotels`);
      const res = await builtInFetch(
        url,
        {
          method: 'POST',
          body: JSON.stringify({
            cityId,
            currency: i18n.culture.currency,
            destinationId,
            historyPrice,
            hotelId,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        true,
      );
      return res.json();
    },

    poisPrice: async ({ entityId }: { entityId: any }) => {
      const url = getBackendUrl('/pois/v1/poi-price', {
        cityId: entityId,
        locale: i18n.culture.locale,
        currency: i18n.culture.currency,
      });
      const response = await builtInFetch(url);
      if (!response.ok) {
        logger.warn(`Fail to fetch /pois/v1/poi-price: ${response.status}`);
        return [];
      }
      return response.json();
    },

    poisEntity: async ({ entityIds }: { entityIds: any }) => {
      const url = getBackendUrl('/pois/v1/entity', {
        entities: entityIds,
        locale: i18n.culture.locale,
      });
      const response = await builtInFetch(url);
      return response.json();
    },
  };
};

export default BackendGateway;
