import classnames from 'classnames';

import BpkBadge, {
  BADGE_TYPES,
} from '@skyscanner/backpack-web/bpk-component-badge';
import type { BadgeType } from '@skyscanner/backpack-web/bpk-component-badge/src/BpkBadge';
import BpkSmallPriceTagIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/price-tag';

import { withConfiguration } from '../../skyscanner-application/configuration';
import { withI18n } from '../../skyscanner-application/i18n';

import type { ConfigurationShape } from '../../skyscanner-application/configuration';
import type { I18nShape } from '../../skyscanner-application/i18n';
import type { Maybe } from '../types';

import STYLES from './CugBadge.module.scss';

type Props = {
  i18n: I18nShape;
  configs: ConfigurationShape;
  className?: Maybe<string>;
  discount?: Maybe<number>;
  type: string;
  isMobile?: boolean;
  alignLeft?: boolean;
  inverse?: boolean;
  showBadgeOnBottom?: boolean;
};

const BIG_SAVING_KEY = 'Badge_discountName_great_saving';
const GENERAL = 'general';
const CAMPAIGN_DEAL = 'campaign_deal';

const badgeTranslationMap: { [key: string]: string } = {
  mobile: 'Badge_discountName_mobile_only',
  logged_in: 'Badge_discountName_logged_in',
};

const bigSavingCugNameTypes = [
  'flight_search',
  'flight_customer',
  'flight_booked',
  'hotel_search',
  'hot_deal',
  'hotel_booked',
  'hotel_customer',
  'car_search',
  'car_booked',
  'car_customer',
];

const notShowLabelTypes = ['business', 'loyalty', GENERAL];
const strongThemeTypes = [CAMPAIGN_DEAL, GENERAL];
const percentageLabelTypes = ['mobile', 'logged_in', ...strongThemeTypes];

const CugBadge = ({
  alignLeft = false,
  className = null,
  configs,
  discount = null,
  i18n,
  inverse = false,
  isMobile = false,
  showBadgeOnBottom = false,
  type,
}: Props) => {
  if (!isMobile && type === 'mobile') {
    return null;
  }
  let cugNameKey;
  let cugPercentageKey;
  let replacements;
  let cugPercentageType: BadgeType = inverse
    ? BADGE_TYPES.inverse
    : BADGE_TYPES.normal;

  const isBigSaving = bigSavingCugNameTypes.findIndex((t) => t === type) >= 0;
  const isWithoutLabel = notShowLabelTypes.findIndex((t) => t === type) >= 0;

  // hotelCardBlueThemeEnabled is TRUE in config service
  // https://configuration.skyscannertools.net/components/hotels_front_end/hotel_card_blue_theme_enabled#/
  const blueThemeEnabled = true; // configs && configs.hotelCardBlueThemeEnabled;

  cugNameKey = badgeTranslationMap[type];
  if (!discount && isBigSaving) {
    cugNameKey = BIG_SAVING_KEY;
  }
  if (!discount && isWithoutLabel) {
    cugNameKey = null;
  }
  if (percentageLabelTypes.includes(type)) {
    cugPercentageKey = 'Badge_discount_percentage';
    replacements = { '0': discount };
    if (strongThemeTypes.includes(type)) {
      cugPercentageType = BADGE_TYPES.strong;
    }
  }
  if (discount && isBigSaving) {
    cugPercentageKey = 'HotelCard_label_savePercentage';
    replacements = { pct_saving: discount };
    cugPercentageType = BADGE_TYPES.strong;
  }

  return (
    <div
      className={classnames(
        STYLES.CugBadge,
        { [STYLES.alignLeft]: alignLeft, [STYLES.onBottom]: showBadgeOnBottom },
        className,
      )}
      data-test-id="hotel-card-badge"
    >
      {!showBadgeOnBottom && cugNameKey && (
        <BpkBadge
          type={BADGE_TYPES.strong}
          className={classnames(STYLES.cugName, {
            [STYLES.colorFeatured]: blueThemeEnabled,
          })}
        >
          {i18n.translate(cugNameKey)}
        </BpkBadge>
      )}
      {discount && cugPercentageKey && replacements && (
        <BpkBadge
          type={cugPercentageType}
          className={classnames(STYLES.discount, {
            [STYLES.withoutLabel]: isWithoutLabel,
            [STYLES.colorFeatured]:
              blueThemeEnabled && cugPercentageType === BADGE_TYPES.strong,
          })}
        >
          {blueThemeEnabled && showBadgeOnBottom && (
            <BpkSmallPriceTagIcon className={STYLES.icon} />
          )}
          {i18n.translate(cugPercentageKey, replacements)}
        </BpkBadge>
      )}
    </div>
  );
};

export default withConfiguration(withI18n(CugBadge));
export { CAMPAIGN_DEAL };
