import classnames from 'classnames';

import ThumbSmIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/thumbs-up';

import {
  FormattedMessage,
  withI18n,
} from '../../../hotels-legacy/client/src/skyscanner-application/i18n';
import { InlineTextSm, ParagraphSm } from '../../HotelsCommon/Text';

import type {
  I18n,
  ReviewForConfidentMsgShape,
} from 'common-types/types/hotels-components/types';

import STYLES from './ConfidentMessage.module.scss';

const RATING_MAP = {
  ratingLocationGreat: {
    key: 'Hotel_card_location_label',
    icon: ThumbSmIcon,
    className: {
      root: classnames(STYLES.Item, STYLES.location),
      icon: STYLES.icon,
    },
  },
};

type ConfidentItemProps = {
  i18n: I18n;
  item: ReviewForConfidentMsgShape;
};

const ConfidentItem = ({ i18n, item }: ConfidentItemProps) => {
  const { ratingType, score } = item;
  if (ratingType && RATING_MAP[ratingType as keyof typeof RATING_MAP]) {
    const ratingObj = RATING_MAP[ratingType as keyof typeof RATING_MAP];
    const RatingIcon = ratingObj.icon;
    const ratingClassName = ratingObj.className;
    const ratingScore = (
      <span className={STYLES.rating}>
        <span className={STYLES.ratingNumerator}>
          {i18n.formatNumber(score, 1)}
        </span>
        <span>/</span>
        <span className={STYLES.ratingDenominator}>5</span>
      </span>
    );

    return (
      <ParagraphSm className={ratingClassName.root}>
        <RatingIcon className={ratingClassName.icon} />
        <InlineTextSm className={STYLES.msg}>
          <FormattedMessage
            label={ratingObj.key}
            data={{ rating: ratingScore }}
          />
        </InlineTextSm>
      </ParagraphSm>
    );
  }
  return null;
};

type ConfidentMessageProps = {
  i18n: I18n;
  reviewForConfidentMsgList: ReviewForConfidentMsgShape[];
  className?: string;
  numberOfReviews: number;
};

const ConfidentMessage = ({
  className = undefined,
  i18n,
  numberOfReviews,
  reviewForConfidentMsgList,
}: ConfidentMessageProps) =>
  numberOfReviews && numberOfReviews > 200 ? (
    <div className={classnames(STYLES.ConfidentMessage, className)}>
      {reviewForConfidentMsgList.map((item) => (
        <ConfidentItem key={item.ratingType} item={item} i18n={i18n} />
      ))}
    </div>
  ) : null;

export default withI18n(ConfidentMessage);
