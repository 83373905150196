import { Component } from 'react';
import type { ReactNode } from 'react';

import classnames from 'classnames';
import debounce from 'lodash/debounce';

import BpkCard from '@skyscanner/backpack-web/bpk-component-card';
import BpkTicket from '@skyscanner/backpack-web/bpk-component-ticket/src/BpkTicket';

import { withFeatureTests } from '../../../hotels-legacy/client/src/skyscanner-application/feature-tests';

import type { FeatureTestsType } from '../../../hotels-legacy/client/src/skyscanner-application/types';

import STYLES from './CardRowLayout.module.scss';

const DEBOUNCE_TIME = 100;

type CardRowLayoutProps = {
  featureTests: FeatureTestsType;
  hotelId: string;
  name?: ReactNode;
  badges?: ReactNode;
  distance?: ReactNode;
  mainPrice?: ReactNode;
  otherPrices?: ReactNode;
  cardHighlightClsName?: string;
  pivotHighlight?: boolean;
  reviews?: ReactNode;
  url?: string;
  onClick?: Function;
  stars?: ReactNode;
  confidentMsg?: ReactNode;
  getImageNode?: Function;
  onHotelCardHover?: Function;
};

type CardRowLayoutState = {
  mouseStatus: string;
};

class CardRowLayout extends Component<CardRowLayoutProps, CardRowLayoutState> {
  static defaultProps = {
    name: undefined,
    badges: undefined,
    distance: undefined,
    mainPrice: undefined,
    otherPrices: undefined,
    cardHighlightClsName: undefined,
    pivotHighlight: false,
    reviews: undefined,
    url: undefined,
    onClick: undefined,
    stars: undefined,
    confidentMsg: undefined,
    getImageNode: () => {},
    onHotelCardHover: undefined,
  };

  private expImageGalleryEnable: any;

  private debouncedMouseEnter: (event: string) => void;

  constructor(props: CardRowLayoutProps) {
    super(props);

    const { featureTests } = props;
    this.expImageGalleryEnable =
      featureTests && featureTests.variant('BD_show_image_gallery_dv', 'b');

    this.state = {
      mouseStatus: this.expImageGalleryEnable ? 'enter' : 'leave',
    };

    this.debouncedMouseEnter = () => {};
  }

  componentDidMount() {
    this.debouncedMouseEnter = debounce(this.mouseHover, DEBOUNCE_TIME);
  }

  mouseHover = (type: string) => {
    if (!this.expImageGalleryEnable) {
      this.setState({ mouseStatus: type });
    }

    if (type === 'enter') {
      const { hotelId, onHotelCardHover } = this.props;
      if (onHotelCardHover) {
        onHotelCardHover(hotelId);
      }
    }
  };

  onMouseEnter = () => {
    this.debouncedMouseEnter('enter');
  };

  onMouseLeave = () => {
    this.debouncedMouseEnter('leave');
  };

  render() {
    const {
      badges,
      cardHighlightClsName,
      confidentMsg,
      distance,
      featureTests,
      getImageNode,
      mainPrice,
      name,
      onClick,
      otherPrices,
      pivotHighlight,
      reviews,
      stars,
      url,
    } = this.props;

    const { mouseStatus } = this.state;

    const expImageGalleryEnable =
      featureTests && featureTests.variant('BD_show_image_gallery_dv', 'b');

    return (
      <div
        className={classnames(STYLES.CardRowLayout, cardHighlightClsName)}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <BpkCard
          padded={false}
          className={STYLES.CardRowLayout__colCard}
          data-test-id={expImageGalleryEnable ? null : 'hotel-card'}
          href={expImageGalleryEnable ? null : url}
          target={expImageGalleryEnable ? null : '_blank'}
        >
          <div
            className={classnames(STYLES.CardRowLayout__colLeft, {
              [STYLES.CardRowLayout__colLeftPivot]: pivotHighlight,
            })}
          >
            {getImageNode?.(mouseStatus)}
          </div>
        </BpkCard>
        <BpkTicket
          stub={mainPrice}
          className={classnames(STYLES.CardRowLayout__ticket, {
            [STYLES.CardRowLayout__ticketPivot]: pivotHighlight,
          })}
          stubClassName={classnames(STYLES.CardRowLayout__stub, {
            [STYLES.CardRowLayout__stubPivot]: pivotHighlight,
          })}
          padded
        >
          <BpkCard
            className={classnames(
              STYLES.CardRowLayout__card,
              STYLES['CardRowLayout__card--whiteBorder'],
            )}
            onClick={() => {
              if (onClick !== null) onClick?.(true, null, true, 'base');
            }}
            target="_blank"
            href={url}
            padded={false}
          >
            <div className={STYLES.CardRowLayout__baseInfo}>
              <div className={STYLES.CardRowLayout__basicInformation}>
                {name}
                <div className={STYLES.CardRowLayout__stars}>{stars}</div>
                <div className={STYLES.CardRowLayout__badge}>{badges}</div>
              </div>
              <div className={STYLES.CardRowLayout__distance}>{distance}</div>
              {reviews}
              {confidentMsg && (
                <div className={STYLES.CardRowLayout__confidentMsg}>
                  {confidentMsg}
                </div>
              )}
            </div>
            {otherPrices && (
              <div className={STYLES.CardRowLayout__otherPrices}>
                {otherPrices}
              </div>
            )}
          </BpkCard>
        </BpkTicket>
      </div>
    );
  }
}

export default withFeatureTests(CardRowLayout);
