import type { ReactNode } from 'react';

import classnames from 'classnames';

import BpkButton from '@skyscanner/backpack-web/bpk-component-button';
import BpkCard from '@skyscanner/backpack-web/bpk-component-card';

import BookOnWebsite from '../../../hotels-legacy/client/src/components/BookOnWebsite';
import OTALogo from '../../../hotels-legacy/client/src/components/OTALogo';
import { isShowHotelName } from '../../../hotels-legacy/client/src/services/officialWebsite';
import { withI18n } from '../../../hotels-legacy/client/src/skyscanner-application/i18n';
import { InlineTextLabel3, InlineTextXs } from '../../HotelsCommon/Text';
import RoomRateAmenities from '../RoomRateAmenities';

import type { I18n, Price } from 'common-types/types/hotels-components/types';

import STYLES from './MainRate.module.scss';

type MainRateProps = {
  i18n: I18n;
  mainPrice: Price;
  fssBadge?: ReactNode;
  couponBadge?: ReactNode;
  priceNode: ReactNode;
  stayPricesSummary?: ReactNode;
  pricePolicy?: ReactNode;
  url?: string;
  onClick?: Function;
  detailsUrl: string;
  goToDVEnabled?: boolean;
};

const MainRate = ({
  couponBadge = undefined,
  detailsUrl,
  fssBadge = undefined,
  goToDVEnabled = false,
  i18n,
  mainPrice,
  onClick = undefined,
  priceNode,
  pricePolicy = undefined,
  stayPricesSummary = undefined,
  url = undefined,
}: MainRateProps) => {
  const {
    amenities,
    funnelType,
    isOfficial,
    logoLink,
    name: partnerName,
    partnerId,
    partnerType,
  } = mainPrice;

  const showHotelName = isShowHotelName(isOfficial, partnerId, partnerType);

  const HotelNameOrLogo = showHotelName ? (
    <BookOnWebsite className={STYLES.MainRate__hotelName} />
  ) : (
    <OTALogo
      src={logoLink}
      partnerId={partnerId}
      name={partnerName}
      height={19}
    />
  );

  const logClassName =
    isOfficial && (fssBadge || couponBadge)
      ? STYLES.MainRate__logoOrName
      : classnames(STYLES.MainRate__logo, {
          [STYLES.MainRate__logoAgoda]: partnerId === 'h_ad',
        });

  const redirectButton = () => (
    <BpkButton
      href={url}
      blank
      className={STYLES.MainRate__cta}
      onClick={(e: MouseEvent) => {
        e.stopPropagation();
        onClick?.(true, mainPrice, false, 'lowestPriceBtn');
      }}
    >
      {goToDVEnabled
        ? i18n.translate('OTARates_button_viewHotel')
        : i18n.translate('OTARates_button_goToSite')}
    </BpkButton>
  );

  const dealButton =
    funnelType === 'dbook' ? (
      <BpkButton className={STYLES.MainRate__cta}>
        {goToDVEnabled
          ? i18n.translate('OTARates_button_viewHotel')
          : i18n.translate('coupon_modal_view_details')}
      </BpkButton>
    ) : (
      redirectButton()
    );

  const replacementTagFormat = (text: string, index: number) => (
    <InlineTextLabel3
      key={index}
      className={classnames({
        [STYLES.MainRate__specificText]:
          i18n.culture.locale === 'en-GB' || i18n.culture.locale === 'en-US',
      })}
    >
      {text}
    </InlineTextLabel3>
  );

  const replacements = {
    ...{
      tag: {
        pair: '/tag',
        format: replacementTagFormat,
      },
    },
  };

  const getCardUrl = () =>
    funnelType === 'dbook' ? `${detailsUrl}&need_scroll=true` : detailsUrl;

  return (
    <div className={STYLES.MainRateWrapper}>
      <BpkCard
        className={STYLES.MainRate}
        onClick={() => {
          onClick?.(true, mainPrice, true, 'stub');
        }}
        target="_blank"
        href={getCardUrl()}
        padded={false}
      >
        <div className={STYLES.MainRate__rate}>
          <div className={STYLES.MainRate__lowestPriceLabel}>
            <div className={STYLES.MainRate__text}>
              <InlineTextXs className={STYLES.MainRate__lowestPrice}>
                {i18n.translate('Hotel_card_lowestPrice_label', replacements)}
              </InlineTextXs>
            </div>
          </div>
          <div className={logClassName}>{HotelNameOrLogo}</div>
          {couponBadge || fssBadge || priceNode}
          <div>
            {stayPricesSummary}
            {pricePolicy}
          </div>
        </div>
        <div className={STYLES.MainRate__ctaAndAmenity}>
          <RoomRateAmenities amenities={amenities} />
          <div className={STYLES.MainRate__ctaButton}>{dealButton}</div>
        </div>
      </BpkCard>
    </div>
  );
};

export default withI18n(MainRate);
