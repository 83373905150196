import { Component } from 'react';
import type { ReactNode } from 'react';

import classnames from 'classnames';
import debounce from 'lodash/debounce';

import BpkCard from '@skyscanner/backpack-web/bpk-component-card';

import { withFeatureTests } from '../../../hotels-legacy/client/src/skyscanner-application/feature-tests';

import type { FeatureTestsType } from '../../../hotels-legacy/client/src/skyscanner-application/types';

import STYLES from './CardLayout.module.scss';

const DEBOUNCE_TIME = 100;

type CardLayoutProps = {
  featureTests: FeatureTestsType;
  hotelId?: string;
  name?: ReactNode;
  stars?: ReactNode;
  badges?: ReactNode;
  distance?: ReactNode;
  seeDetails?: ReactNode;
  priceAndNumbers?: ReactNode;
  url?: string;
  cardHighlightClsName?: string;
  onClick?: Function;
  pricePolicy?: ReactNode;
  stayPricesSummary?: ReactNode;
  reviewSummary?: ReactNode;
  getImageNode?: Function;
  confidentMsg?: ReactNode;
  onHotelCardHover?: Function;
};

type CardLayoutState = {
  mouseStatus: string;
};

class CardLayout extends Component<CardLayoutProps, CardLayoutState> {
  static defaultProps = {
    hotelId: undefined,
    name: undefined,
    stars: undefined,
    badges: undefined,
    distance: undefined,
    seeDetails: undefined,
    priceAndNumbers: undefined,
    url: undefined,
    cardHighlightClsName: undefined,
    onClick: undefined,
    pricePolicy: undefined,
    stayPricesSummary: undefined,
    reviewSummary: undefined,
    getImageNode: () => {},
    confidentMsg: undefined,
    onHotelCardHover: undefined,
  };

  private expImageGalleryEnable: boolean;

  private debouncedMouseEnter: (event: string) => void;

  constructor(props: CardLayoutProps) {
    super(props);

    const { featureTests } = props;
    this.expImageGalleryEnable =
      featureTests && featureTests.variant('BD_show_image_gallery_dv', 'b');

    this.state = {
      mouseStatus: this.expImageGalleryEnable ? 'enter' : 'leave',
    };

    this.debouncedMouseEnter = () => {};
  }

  componentDidMount() {
    this.debouncedMouseEnter = debounce(this.mouseHover, DEBOUNCE_TIME);
  }

  mouseHover = (type: string) => {
    if (!this.expImageGalleryEnable) {
      this.setState({ mouseStatus: type });
    }

    if (type === 'enter') {
      const { hotelId, onHotelCardHover } = this.props;
      if (onHotelCardHover) {
        onHotelCardHover(hotelId);
      }
    }
  };

  onMouseEnter = () => {
    this.debouncedMouseEnter('enter');
  };

  onMouseLeave = () => {
    this.debouncedMouseEnter('leave');
  };

  render() {
    const {
      badges,
      cardHighlightClsName,
      confidentMsg,
      distance,
      featureTests,
      getImageNode,
      name,
      onClick,
      priceAndNumbers,
      pricePolicy,
      reviewSummary,
      seeDetails,
      stars,
      stayPricesSummary,
      url,
    } = this.props;

    const { mouseStatus } = this.state;

    const expImageGalleryEnable =
      featureTests && featureTests.variant('BD_show_image_gallery_dv', 'b');

    return (
      <div
        className={STYLES.HotelCardsListChunk__card}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div className={STYLES.CardLayoutWrapper}>
          <BpkCard
            className={classnames(STYLES.CardLayout, cardHighlightClsName)}
            padded={false}
            href={expImageGalleryEnable ? null : url}
            data-testid="hotel-card"
            onClick={() => {
              if (!expImageGalleryEnable && onClick !== null)
                onClick?.(true, null, true, 'card');
            }}
          >
            <div className={STYLES.CardLayout__colLeft}>
              {getImageNode?.(mouseStatus)}
            </div>

            <BpkCard
              padded={false}
              href={url}
              className={STYLES.CardLayout__colCard}
            >
              <div className={STYLES.CardLayout__colRight}>
                <div className={STYLES.CardLayout__basicInformation}>
                  {name}
                  <div className={STYLES.CardLayout__stars}>{stars}</div>
                  <div className={STYLES.CardLayout__badge}>{badges}</div>
                </div>
                <div>
                  <div className={STYLES.CardLayout__distance}>{distance}</div>
                  {reviewSummary}
                  {confidentMsg && (
                    <div className={STYLES.CardLayout__confidentMsg}>
                      {confidentMsg}
                    </div>
                  )}
                </div>
              </div>
              <div className={STYLES.CardLayout__extraColRight}>
                <div className={STYLES.CardLayout__extraColRightRowCenter}>
                  {priceAndNumbers}
                  {stayPricesSummary}
                  {pricePolicy}
                </div>
                <div className={STYLES.CardLayout__extraColRightRowBottom}>
                  {seeDetails}
                </div>
              </div>
            </BpkCard>
          </BpkCard>
        </div>
      </div>
    );
  }
}

export default withFeatureTests(CardLayout);
