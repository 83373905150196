import {
  colorSagano,
  colorMonteverde,
  colorKolkata,
  colorPanjin,
  colorBagan,
  colorHarbour,
} from '@skyscanner/bpk-foundations-web/tokens/base.es6';

import {
  colorYellow500,
  colorYellow700,
  colorGreen400,
  colorGreen500,
  colorGreen700,
  colorGreen900,
  colorRed500,
} from './colors';

type Score = {
  threshold: number;
  summary: string;
  color: string;
};

const scoreBrackets: Score[] = [
  { threshold: 0, summary: 'rating_below_average', color: colorRed500 },
  { threshold: 3, summary: 'rating_average', color: colorYellow700 },
  { threshold: 6, summary: 'rating_satisfactory', color: colorYellow500 },
  { threshold: 7, summary: 'rating_good', color: colorGreen400 },
  { threshold: 8, summary: 'rating_very_good', color: colorGreen500 },
  { threshold: 9, summary: 'rating_excellent', color: colorGreen700 },
  { threshold: 9.5, summary: 'rating_with_honors', color: colorGreen900 },
];

const scoreNewBrackets: Score[] = [
  { threshold: 0, summary: 'rating_below_average', color: colorPanjin },
  { threshold: 3, summary: 'rating_average', color: colorPanjin },
  { threshold: 6, summary: 'rating_satisfactory', color: colorKolkata },
  { threshold: 7, summary: 'rating_good', color: colorKolkata },
  { threshold: 8, summary: 'rating_very_good', color: colorMonteverde },
  { threshold: 9, summary: 'rating_excellent', color: colorMonteverde },
  { threshold: 9.5, summary: 'rating_with_honors', color: colorMonteverde },
];

const scoreBracketsForReview: Score[] = [
  { threshold: 0, summary: 'rating_below_average', color: colorPanjin },
  { threshold: 1.5, summary: 'rating_average', color: colorPanjin },
  { threshold: 3, summary: 'rating_satisfactory', color: colorKolkata },
  { threshold: 3.5, summary: 'rating_good', color: colorKolkata },
  { threshold: 4, summary: 'rating_very_good', color: colorMonteverde },
  { threshold: 4.5, summary: 'rating_excellent', color: colorMonteverde },
  { threshold: 5, summary: 'rating_with_honors', color: colorMonteverde },
];

const scoreBracketsForReviewTint: Score[] = [
  { threshold: 0, summary: 'rating_below_average', color: colorHarbour },
  { threshold: 1.5, summary: 'rating_average', color: colorHarbour },
  { threshold: 3, summary: 'rating_satisfactory', color: colorBagan },
  { threshold: 3.5, summary: 'rating_good', color: colorBagan },
  { threshold: 4, summary: 'rating_very_good', color: colorSagano },
  { threshold: 4.5, summary: 'rating_excellent', color: colorSagano },
  { threshold: 5, summary: 'rating_with_honors', color: colorSagano },
];

const scoreColorForReviewTint: Score[] = [
  { threshold: 0, summary: 'rating_below_average', color: colorPanjin },
  { threshold: 1.5, summary: 'rating_average', color: colorPanjin },
  { threshold: 3, summary: 'rating_satisfactory', color: colorKolkata },
  { threshold: 3.5, summary: 'rating_good', color: colorKolkata },
  { threshold: 4, summary: 'rating_very_good', color: colorMonteverde },
  { threshold: 4.5, summary: 'rating_excellent', color: colorMonteverde },
  { threshold: 5, summary: 'rating_with_honors', color: colorMonteverde },
];

const scoreNewColor = (number: number) =>
  scoreNewBrackets.reduce<undefined | string>((foundColor, value, idx) => {
    const { color, threshold } = value;
    const maxIdx = scoreNewBrackets.length - 1;
    const gapIdx = Math.min(maxIdx, idx + 1);
    const gap =
      scoreNewBrackets[gapIdx].threshold - scoreNewBrackets[idx].threshold;

    if (foundColor) {
      return foundColor;
    }

    if (number - gap >= threshold && idx < maxIdx) {
      return undefined;
    }

    return color;
  }, undefined);

const scoreColorForReview = (
  number: number,
  tint?: boolean,
  forColor?: boolean,
) => {
  let scoreBracketsColor = scoreBracketsForReview;
  if (tint) {
    scoreBracketsColor = scoreBracketsForReviewTint;
  }
  if (forColor) {
    scoreBracketsColor = scoreColorForReviewTint;
  }

  return scoreBracketsColor.reduce<undefined | string>(
    (foundColor, value, idx) => {
      const { color, threshold } = value;
      const maxIdx = scoreBracketsColor.length - 1;
      const gapIdx = Math.min(maxIdx, idx + 1);
      const gap =
        scoreBracketsColor[gapIdx].threshold -
        scoreBracketsColor[idx].threshold;

      if (foundColor) {
        return foundColor;
      }

      if (number - gap >= threshold && idx < maxIdx) {
        return undefined;
      }

      return color;
    },
    undefined,
  );
};

const scoreSummaryForReview = (number: number) =>
  scoreBracketsForReview.reduce<undefined | string>(
    (foundSummary, value, idx) => {
      const { summary, threshold } = value;
      const maxIdx = scoreBracketsForReview.length - 1;
      const gapIdx = Math.min(maxIdx, idx + 1);
      const gap =
        scoreBracketsForReview[gapIdx].threshold -
        scoreBracketsForReview[idx].threshold;

      if (foundSummary) {
        return foundSummary;
      }

      if (number - gap >= threshold && idx < maxIdx) {
        return undefined;
      }
      return summary;
    },
    undefined,
  );

export {
  scoreBrackets,
  scoreNewBrackets,
  scoreBracketsForReview,
  scoreNewColor,
  scoreColorForReview,
  scoreSummaryForReview,
};
