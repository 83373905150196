import { Component } from 'react';

import classnames from 'classnames';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';

import BpkCard from '@skyscanner/backpack-web/bpk-component-card';

import { withFeatureTests } from '../../../hotels-legacy/client/src/skyscanner-application/feature-tests';
import AlternativeImage from '../../HotelsCommon/AlternativeImage';
import ProgressivePicture from '../../HotelsCommon/ProgressivePicture';
import CardThumbnailImage from '../CardThumbnailImage';

import type { FeatureTestsType } from '../../../hotels-legacy/client/src/skyscanner-application/types';

import STYLES from './CardMultiImage.module.scss';

const MAIN_IMAGE_WIDTH = 320;
const MAIN_IMAGE_HEIGHT = 252;
const THUMBNAIL_IMAGE_WIDTH = 80;
const THUMBNAIL_IMAGE_HEIGHT = 80;
const MOUSE_HOVER_DELAY = 200;
const DEBOUNCE_TIME = 150;
const TABLET_WIDTH = 768;

type Props = {
  featureTests: FeatureTestsType;
  images?: string[];
  totalImages: number;
  className?: string;
  mouseEnter: boolean;
  onClick?: Function;
  url?: string;
  isFrench?: boolean;
};

const defaultProps = {
  className: undefined,
  onClick: undefined,
  url: undefined,
  isFrench: false,
  images: undefined,
};

type State = {
  currentIndex: number;
  thumbnailImageLength: number;
  showThumbnailImage: boolean;
};

class CardMultiImage extends Component<Props, State> {
  static defaultProps = defaultProps;

  mouseEnterTimerID: ReturnType<typeof setTimeout> | null;

  constructor(props: Props) {
    super(props);

    this.state = {
      currentIndex: 0,
      thumbnailImageLength: 5,
      showThumbnailImage: this.props.mouseEnter,
    };

    this.mouseEnterTimerID = null;
  }

  static getDerivedStateFromProps(nextProps: Props) {
    if (nextProps.mouseEnter) {
      return {
        showThumbnailImage: true,
      };
    }

    return null;
  }

  componentDidMount() {
    window.addEventListener('resize', this.setThumbnailImageLength);
  }

  componentWillUnmount() {
    if (this.mouseEnterTimerID) {
      clearTimeout(this.mouseEnterTimerID);
    }

    window.removeEventListener('resize', this.setThumbnailImageLength);
  }

  setThumbnailImageLength = debounce(() => {
    if (window.innerWidth <= TABLET_WIDTH) {
      this.setState({
        thumbnailImageLength: 4,
      });
    } else {
      this.setState({
        thumbnailImageLength: 5,
      });
    }
  }, DEBOUNCE_TIME);

  changeImage = (currentIndex: number) => {
    this.setState({
      currentIndex,
    });
  };

  generateImage = (
    src: string,
    size: { width: number; height: number },
    index: number,
    mediaType: string,
    lazyLoad: boolean,
    arr: string[] = [],
    onClick?: Function,
  ) => {
    const { featureTests, totalImages } = this.props;

    const { currentIndex, thumbnailImageLength } = this.state;

    const expImageGalleryEnable =
      featureTests && featureTests.variant('BD_show_image_gallery_dv', 'b');

    const overlay = (
      <div
        role="presentation"
        onKeyDown={noop}
        data-testid={`overlay-${index}`}
        onClick={() => {
          if (onClick) {
            const eventArea = mediaType === 'main' ? 'image' : 'galleryImage';
            onClick(true, null, true, eventArea);
          }
        }}
        className={classnames(STYLES.CardMultiImage__imgOverlay, {
          [STYLES['CardMultiImage__imgOverlay--hover']]: !expImageGalleryEnable,
          [STYLES['CardMultiImage__imgOverlay--main']]: mediaType === 'main',
          [STYLES['CardMultiImage__imgOverlay--active']]:
            currentIndex === index,
        })}
      >
        {mediaType !== 'main' &&
          arr.length === index + 1 &&
          totalImages > thumbnailImageLength && (
            <span className={STYLES.CardMultiImage__imgCount}>
              +{totalImages - thumbnailImageLength}
            </span>
          )}
      </div>
    );

    return (
      <div
        className={classnames(
          STYLES[`CardMultiImage__${mediaType}ImgContainer`],
          {
            [STYLES['CardMultiImage__mainImgContainer--active']]:
              currentIndex === index && mediaType === 'main',
          },
        )}
        onMouseEnter={() => {
          if (!expImageGalleryEnable && mediaType !== 'main') {
            this.mouseEnterTimerID = setTimeout(() => {
              this.changeImage(index);
            }, MOUSE_HOVER_DELAY);
          }
        }}
        onMouseLeave={() => {
          if (
            !expImageGalleryEnable &&
            mediaType !== 'main' &&
            this.mouseEnterTimerID
          ) {
            clearTimeout(this.mouseEnterTimerID);
            this.mouseEnterTimerID = null;
          }
        }}
        onFocus={() => {
          if (mediaType !== 'main') {
            this.changeImage(index);
          }
        }}
        key={src}
      >
        <ProgressivePicture
          lazyLoad={lazyLoad}
          dataTestId="media-grid-item"
          className={STYLES[`CardMultiImage__${mediaType}Img`]}
          lowQualityClassName={
            STYLES[`CardMultiImage__${mediaType}LowQualityImg`]
          }
          sources={[
            {
              srcSet: [
                `${src.replace('_WxH', `_${size.width}x${size.height}`)}`,
                `${src.replace(
                  '_WxH',
                  `_${size.width * 2}x${size.height * 2}`,
                )} 2x`,
              ],
            },
          ]}
          alt=""
          src={src}
        />
        {overlay}
      </div>
    );
  };

  render() {
    const {
      className,
      featureTests,
      images: originImages,
      isFrench,
      mouseEnter,
      onClick,
      url,
    } = this.props;
    const { showThumbnailImage, thumbnailImageLength } = this.state;

    if (!originImages || !originImages.length) {
      return (
        <AlternativeImage
          className={classnames(
            STYLES.CardMultiImage__mainImgContainer,
            STYLES['CardMultiImage__mainImgContainer--active'],
          )}
          alt="Hotel"
        />
      );
    }

    const expImageGalleryEnable =
      featureTests && featureTests.variant('BD_show_image_gallery_dv', 'b');

    const images = originImages.slice(0, thumbnailImageLength);
    const mainImage = showThumbnailImage ? images : images.slice(0, 1);

    const mainImageChild = mainImage.map((imageSrc, index) =>
      this.generateImage(
        imageSrc,
        {
          width: MAIN_IMAGE_WIDTH,
          height: MAIN_IMAGE_HEIGHT,
        },
        index,
        'main',
        true,
        [],
        onClick,
      ),
    );

    const mainCard = expImageGalleryEnable ? (
      <BpkCard
        target={isFrench ? null : '_blank'}
        href={url}
        padded={false}
        className={STYLES.CardMultiImage__mainCard}
        data-test-id="hotel-card"
      >
        {mainImageChild}
      </BpkCard>
    ) : (
      mainImageChild
    );

    return (
      <section className={classnames(STYLES.CardMultiImage, className)}>
        {mainCard}

        {showThumbnailImage && images.length > 1 && (
          <CardThumbnailImage mouseEnter={mouseEnter}>
            <div className={STYLES.CardMultiImage__thumbnailContainer}>
              {images.map((imageSrc, index, array) =>
                this.generateImage(
                  imageSrc,
                  {
                    width: THUMBNAIL_IMAGE_WIDTH,
                    height: THUMBNAIL_IMAGE_HEIGHT,
                  },
                  index,
                  'thumbnail',
                  false,
                  array,
                  onClick,
                ),
              )}
            </div>
          </CardThumbnailImage>
        )}
      </section>
    );
  }
}

export default withFeatureTests(CardMultiImage);
