import classnames from 'classnames';

import { withI18n } from '../../hotels-legacy/client/src/skyscanner-application/i18n';
import { getId } from '../../hotels-legacy/client/src/utils/a11y';
import { InlineTextSm } from '../HotelsCommon/Text';

import getPositiveAmenities from './getPositiveAmenities';

import type { AmenityShape } from '../../hotels-legacy/client/src/components/types';
import type { I18n } from 'common-types/types/hotels-components/types';

import STYLES from './RoomRateAmenities.module.scss';

const specialAmenities = ['free_cancellation', 'refundable'];

type AmenityItemProps = {
  amenity: AmenityShape;
  className?: string;
  hotelCardAccessibility?: boolean;
  i18n: I18n;
};

const AmenityItem = withI18n(
  ({
    amenity,
    className = undefined,
    hotelCardAccessibility = false,
    i18n,
  }: AmenityItemProps) => {
    const { name } = amenity;
    const isSpecialAmenity = name ? specialAmenities.includes(name) : false;
    const itemName = amenity.displayName || (name && i18n.translate(name));
    if (hotelCardAccessibility) {
      return (
        <span aria-hidden className={className}>
          {itemName}
        </span>
      );
    }
    return (
      <InlineTextSm
        className={classnames(STYLES.amenity, className, {
          [STYLES.specialAmenity]: isSpecialAmenity,
        })}
      >
        {itemName}
      </InlineTextSm>
    );
  },
);

type RoomRateAmenitiesProps = {
  amenities?: AmenityShape[];
  className?: string;
  hotelCardAccessibility?: boolean;
  i18n: I18n;
  index?: number;
};

const RoomRateAmenities = ({
  amenities = undefined,
  className = undefined,
  hotelCardAccessibility = false,
  i18n,
  index = undefined,
}: RoomRateAmenitiesProps) => {
  const positiveAmenity = getPositiveAmenities(amenities);
  const positiveAmenityItem = positiveAmenity.map((amenity) => (
    <AmenityItem
      amenity={amenity}
      key={amenity.name}
      className={className}
      hotelCardAccessibility={hotelCardAccessibility}
    />
  ));
  const dot = (key: string) => (
    <span key={key} className={STYLES.dot} aria-hidden>
      ・
    </span>
  );
  const amenityLength = positiveAmenityItem.length;

  if (hotelCardAccessibility && amenityLength > 0) {
    let ariaLabel;
    const positiveAmenityName = positiveAmenity.map(
      (amenity) => amenity.displayName,
    );
    if (amenityLength === 1) {
      ariaLabel = i18n.translate(
        'aria_oneAmenity',
        {
          amenity: positiveAmenityName[0],
        },
        '{',
        '}',
        true,
      );
    } else {
      const last = positiveAmenityName[amenityLength - 1];
      const rest = positiveAmenityName
        .filter((amenityName) => amenityName !== last)
        .join(', ');
      ariaLabel = i18n.translate(
        'aria_multiAmenity',
        {
          last,
          rest,
        },
        '{',
        '}',
        true,
      );
    }

    return (
      <div
        className={STYLES.accessibility}
        id={getId('rate_amenities', index)}
        aria-label={ariaLabel}
      >
        {positiveAmenityItem.reduce<JSX.Element[] | null>(
          (acc, item, idx) =>
            acc ? [...acc, dot(`dot-${idx}`), item] : [item],
          null,
        )}
      </div>
    );
  }

  return <div className={STYLES.RoomRateAmenities}>{positiveAmenityItem}</div>;
};

export default withI18n(RoomRateAmenities);
