import type { ReactNode } from 'react';

import BpkButton from '@skyscanner/backpack-web/bpk-component-button';
import CloseIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/close';
import BpkModal from '@skyscanner/backpack-web/bpk-component-modal';

import {
  InlineTextHeading3,
  Paragraph,
  ParagraphHeading3,
} from '../../../../../../components/HotelsCommon/Text';
import { getApplicationElement } from '../../../components/Common/ApplicationElements';
import NoneStyleButton from '../../../components/Common/NoneStyleButton';
import { FormattedMessage } from '../../../skyscanner-application/i18n';

import STYLES from './NewModal.module.scss';

type DirectDiscountModalProps = {
  isOpen?: boolean;
  onClick: Function;
  header: ReactNode;
  content: ReactNode;
};

const DirectDiscountModal = ({
  content,
  header,
  isOpen = false,
  onClick,
  ...rest
}: DirectDiscountModalProps) => (
  <BpkModal
    id="direct-discount-modal"
    isOpen={isOpen}
    className={STYLES.Modal}
    showHeader={false}
    fullScreenOnMobile={false}
    getApplicationElement={getApplicationElement}
    {...rest}
  >
    <div className={STYLES.Modal__body}>
      <NoneStyleButton
        className={STYLES.Modal__iconContainer}
        onClick={onClick}
      >
        <CloseIcon />
      </NoneStyleButton>

      <ParagraphHeading3 className={STYLES.Modal__text}>
        {header}
      </ParagraphHeading3>

      <Paragraph className={STYLES.Modal__text}>{content}</Paragraph>

      <BpkButton
        className={STYLES['Modal__button--single']}
        onClick={onClick}
        large
      >
        <InlineTextHeading3>
          <FormattedMessage label="Reviews_GotIt" />
        </InlineTextHeading3>
      </BpkButton>
    </div>
  </BpkModal>
);

export default DirectDiscountModal;
