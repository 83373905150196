import { withI18n } from '../../hotels-legacy/client/src/skyscanner-application/i18n';
import FormattedMessage from '../../hotels-legacy/client/src/skyscanner-application/i18n/FormattedMessage';

import type {
  I18n,
  DistancePOIShape,
} from 'common-types/types/hotels-components/types';

type Props = {
  i18n: I18n;
  relevantPOI: DistancePOIShape;
};

const DistancePOI = ({ i18n, relevantPOI: { distance, name } }: Props) => {
  const distanceNode = i18n.formatDistance(distance);
  return (
    <FormattedMessage
      label="Distance_label_fromPlace"
      data={{
        distance: distanceNode,
        place: name,
      }}
      span
    />
  );
};

export default withI18n(DistancePOI);
