import { GLOBAL } from 'saddlebag-browser';

import BpkCard from '@skyscanner/backpack-web/bpk-component-card';
import BpkImage, {
  withLazyLoading,
  withLoadingBehavior,
} from '@skyscanner/backpack-web/bpk-component-image';
import BpkLoadingButton from '@skyscanner/backpack-web/bpk-component-loading-button';

import getDayviewUrl from '../../hotels-legacy/client/src/services/url/url';
import { withI18n } from '../../hotels-legacy/client/src/skyscanner-application/i18n';
import { Paragraph, ParagraphLabel1 } from '../HotelsCommon/Text';

import SearchHotelsLogo from './search-hotels.svg';

import type { FilterShape } from '../../hotels-legacy/client/src/components/types';
import type { I18nShape } from '../../hotels-legacy/client/src/skyscanner-application/i18n';
import type { Stay } from 'common-types/types/HotelStay';
import type { DestinationShape } from 'common-types/types/hotels-components/types';

import STYLES from './SeoBannerSearchHotels.module.scss';

type Props = {
  i18n: I18nShape;
  stay: Stay;
  destination: DestinationShape;
  fromSeoPage?: string;
  filters?: FilterShape;
};

const documentIfExists = typeof window !== 'undefined' ? document : null;
const LazyLoadedImage = withLoadingBehavior(
  withLazyLoading(BpkImage, documentIfExists),
);

const SeoBannerSearchHotels = ({
  destination,
  filters = undefined,
  fromSeoPage = undefined,
  i18n,
  stay,
}: Props) => {
  const element = 'seo-banner-search-hotels';
  const translateTitleKey =
    fromSeoPage === 'City'
      ? 'HotelCard_seo_search_all_available_hotels_in_location'
      : 'HotelCard_seo_search_all_available_hotels_near_location';

  const dayviewUrl = GLOBAL.getWindow()
    ? getDayviewUrl({
        destination,
        stay,
        filters,
        i18n,
      })
    : null;

  return (
    <div className={STYLES.SeoBannerSearchHotels}>
      <BpkCard className={STYLES.card} padded={false} atomic={false}>
        <div className={STYLES.SeoBannerSearchHotels__image}>
          <LazyLoadedImage
            alt={element}
            className={STYLES.SeoBannerSearchHotels__logo}
            src={SearchHotelsLogo}
            aspectRatio={203 / 152}
          />
        </div>

        <div className={STYLES.SeoBannerSearchHotels__middle}>
          <ParagraphLabel1 className={STYLES.SeoBannerSearchHotels__title}>
            {i18n.translate('HotelCard_seo_canot_find_right_hotel')}
          </ParagraphLabel1>
          <div>
            <Paragraph>
              {i18n.translate(translateTitleKey, {
                location: destination.entity,
              })}
              {i18n.translate('HotelCard_seo_find_the_right_one_for_you')}
            </Paragraph>
          </div>
        </div>
        <div className={STYLES.SeoBannerSearchHotels__btn}>
          <BpkLoadingButton target="_blank" href={dayviewUrl}>
            {i18n.translate('SearchControls_label_Submit')}
          </BpkLoadingButton>
        </div>
      </BpkCard>
    </div>
  );
};

export default withI18n(SeoBannerSearchHotels);
