import { ShimmerCards } from '../../HotelCard';

import STYLES from './ShimmerSearchResults.scss';

type Props = {
  isMobile?: boolean;
  cardNumber?: number;
};

const ShimmerSearchResults = ({ cardNumber = 3, isMobile = false }: Props) => (
  <>
    <div className={STYLES.ShimmerSearchResults__caption} />
    {ShimmerCards(cardNumber, isMobile)}
  </>
);

export default ShimmerSearchResults;
