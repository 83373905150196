import { Component } from 'react';

import { wrapDate, now } from '../../../../common/src/utils/wrap-date';
import displayName from '../display-name';

import getTimeString from './getTimeString';

import type {
  GeneralCoupon,
  I18n,
} from 'common-types/types/hotels-components/types';

type Props = {
  i18n: I18n;
  coupon: GeneralCoupon;
};

type State = {
  timeRemainString: string;
};

const ONE_SECOND = 1000;

const withTimeRemain = (WrappedComponent: any) => {
  const component = class extends Component<Props, State> {
    expirationTime: Date;

    timer: any;

    static displayName: any;

    constructor(props: Props) {
      super(props);

      this.expirationTime = wrapDate(this.props.coupon.expirationDateTime);

      this.state = {
        timeRemainString: '',
      };
    }

    componentDidMount() {
      this.getTimeRemainString();
      this.timer = setInterval(() => {
        this.getTimeRemainString();
      }, ONE_SECOND * 60);
    }

    componentWillUnmount() {
      clearTimeout(this.timer);
    }

    getTimeRemainString() {
      const { i18n } = this.props;
      const timeRemain =
        this.expirationTime.getTime() - wrapDate(now()).getTime();
      if (!timeRemain || timeRemain < 0) {
        this.setState({
          timeRemainString: '',
        });
        return;
      }

      const timeString = getTimeString({ i18n, timeRemain });

      this.setState({
        timeRemainString: timeString,
      });
    }

    render() {
      return (
        <WrappedComponent
          timeRemainString={this.state.timeRemainString}
          {...this.props}
        />
      );
    }
  };

  component.displayName = displayName(WrappedComponent);

  return component;
};

export default withTimeRemain;
