import { PRICE_TYPE_PER_NIGHT } from 'hotels-common/src/services/price-type';

import HotelCard from '../HotelCard';
import HotelSearchResultsCaption from '../HotelSearchResultsCaption';
import { isEmptySearchResults, parseStayProp } from '../HotelsCommon/utils';
import SeoBannerSearchHotels from '../SeoBannerSearchHotels';

import ShimmerSearchResults from './ShimmerSearchResults';

import type {
  SearchResultShape,
  FilterShape,
} from '../../hotels-legacy/client/src/components/types';
import type { Stay } from 'common-types/types/HotelStay';
import type { DestinationShape } from 'common-types/types/hotels-components/types';

import STYLES from './HotelSearchResultsDisplay.module.scss';

type HotelSearchResultsDisplayProps = {
  stay: Stay;
  destination: DestinationShape;
  hotelSearchResults?: SearchResultShape;
  fromSeoPage?: boolean;
  filters?: FilterShape;
  isMobile?: boolean;
  removePartnerRedirectLinks?: boolean;
};

const CARDS_NUMBER = 9;

const HotelCards = (
  hotelCards: any,
  stay: Stay,
  destination: DestinationShape,
  isMobile: boolean,
  fromSeoPage?: boolean,
  filters?: FilterShape,
  removePartnerRedirectLinks?: boolean,
) =>
  hotelCards.slice(0, CARDS_NUMBER).map((hotelCard: any, i: number) => (
    <div key={hotelCard.id} className={STYLES.HotelSearchResultsDisplay__card}>
      <HotelCard
        {...hotelCard}
        {...hotelCard.priceInfo}
        selected={false}
        stay={stay}
        isMobile={isMobile}
        priceType={PRICE_TYPE_PER_NIGHT}
        fromSeoPage={fromSeoPage}
        removePartnerRedirectLinks={removePartnerRedirectLinks}
      />
      {i === 2 && (
        <SeoBannerSearchHotels
          stay={stay}
          destination={destination}
          filters={filters}
        />
      )}
    </div>
  ));

const HotelSearchResultsDisplay = ({
  destination,
  filters = undefined,
  fromSeoPage = undefined,
  hotelSearchResults = undefined,
  isMobile = false,
  removePartnerRedirectLinks = false,
  stay,
}: HotelSearchResultsDisplayProps) => {
  const {
    hotelCards = [],
    loading = true,
    pricePolicy,
  } = hotelSearchResults || {};
  const parsedStay = parseStayProp(stay);

  if (isEmptySearchResults(hotelSearchResults)) {
    return (
      <SeoBannerSearchHotels
        stay={stay}
        destination={destination}
        filters={filters}
      />
    );
  }

  return loading && hotelCards.length === 0 ? (
    <ShimmerSearchResults isMobile={isMobile} />
  ) : (
    <>
      <div className={STYLES.HotelSearchResultsDisplay__totalPrice}>
        {pricePolicy && (
          <HotelSearchResultsCaption
            stay={parsedStay}
            pricePolicy={pricePolicy}
          />
        )}
      </div>
      {HotelCards(
        hotelCards,
        parsedStay,
        destination,
        isMobile,
        fromSeoPage,
        filters,
        removePartnerRedirectLinks,
      )}
    </>
  );
};

export default HotelSearchResultsDisplay;
