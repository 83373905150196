import type { ReactNode } from 'react';

import classnames from 'classnames';

import BpkCard from '@skyscanner/backpack-web/bpk-component-card';

import { InlineTextLabel1 } from '../../HotelsCommon/Text';

import STYLES from './MapCardLayout.module.scss';

type MapCardLayoutProps = {
  imageNode?: ReactNode;
  name?: ReactNode;
  stars?: ReactNode;
  price?: ReactNode;
  url?: string;
  onClick?: Function;
  hotelCardViewed?: boolean;
  distance?: ReactNode;
};

const MapCardLayout = ({
  distance = undefined,
  hotelCardViewed = false,
  imageNode = undefined,
  name = undefined,
  onClick = undefined,
  price = undefined,
  stars = undefined,
  url = undefined,
}: MapCardLayoutProps) => (
  <BpkCard
    className={STYLES.MapCardLayout}
    padded={false}
    href={url}
    target="_blank"
    data-testid="hotel-card"
    onClick={() => {
      if (onClick !== null) onClick?.(true, null, true, 'baseInfoOnMobile');
    }}
  >
    <div className={STYLES.MapCardLayout__image}>{imageNode}</div>
    <div className={STYLES.MapCardLayout__baseInfo}>
      <div className={STYLES.MapCardLayout__card}>
        <div className={STYLES.MapCardLayout__nameContainer}>
          <InlineTextLabel1
            className={classnames(STYLES.MapCardLayout__name, {
              [STYLES['MapCardLayout__name--viewed']]: hotelCardViewed,
            })}
            data-testid="hotel-name"
          >
            {name}
          </InlineTextLabel1>
        </div>
        <div className={STYLES.MapCardLayout__stars}>{stars}</div>
        <div className={STYLES.MapCardLayout__distance}>{distance}</div>
        <div className={STYLES.MapCardLayout__priceInfo}>{price}</div>
      </div>
    </div>
  </BpkCard>
);

export default MapCardLayout;
