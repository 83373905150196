import { Component } from 'react';
import type { ReactNode } from 'react';

import classnames from 'classnames';

import STYLES from './CardThumbnailImage.module.scss';

type CardThumbnailImageProps = {
  children: ReactNode;
  mouseEnter: boolean;
};

type CardThumbnailImageState = {
  mouseEnter: boolean;
};

class CardThumbnailImage extends Component<
  CardThumbnailImageProps,
  CardThumbnailImageState
> {
  private mouseEnterTimerID: NodeJS.Timeout | undefined;

  constructor(props: CardThumbnailImageProps) {
    super(props);

    this.state = {
      mouseEnter: this.props.mouseEnter,
    };

    this.mouseEnterTimerID = undefined;
  }

  static getDerivedStateFromProps(
    nextProps: CardThumbnailImageProps,
    prevState: CardThumbnailImageProps,
  ) {
    if (nextProps.mouseEnter !== prevState.mouseEnter) {
      return {
        mouseEnter: nextProps.mouseEnter,
      };
    }

    return null;
  }

  componentDidMount() {
    this.mouseEnterTimerID = setTimeout(() => {
      this.setState({
        mouseEnter: true,
      });
    }, 0);
  }

  componentWillUnmount() {
    clearTimeout(this.mouseEnterTimerID);
  }

  render() {
    const { children } = this.props;
    const { mouseEnter } = this.state;
    return (
      <section
        className={classnames(
          STYLES.CardThumbnailImage,
          mouseEnter && STYLES.CardThumbnailImage__enter,
        )}
      >
        {children}
      </section>
    );
  }
}

export default CardThumbnailImage;
