import { Component } from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import BpkBadge, {
  BADGE_TYPES,
} from '@skyscanner/backpack-web/bpk-component-badge';
import BpkCard from '@skyscanner/backpack-web/bpk-component-card';
import LocationIconSM from '@skyscanner/backpack-web/bpk-component-icon/sm/location';
import BpkLoadingButton from '@skyscanner/backpack-web/bpk-component-loading-button';
import {
  BpkSpinner,
  SPINNER_TYPES,
} from '@skyscanner/backpack-web/bpk-component-spinner';

import CugBadge from '../../hotels-legacy/client/src/components/CugBadge';
import { getDiscountType } from '../../hotels-legacy/client/src/components/DirectDiscount/discountType';
import GeneralDirectDiscount from '../../hotels-legacy/client/src/components/GeneralDirectDiscount';
import HotelStars from '../../hotels-legacy/client/src/components/HotelStars';
import OTALogo from '../../hotels-legacy/client/src/components/OTALogo';
// import { mark } from '../../../services/performance';
import { withBackendContext } from '../../hotels-legacy/client/src/components/backend-context';
import complyRegulator from '../../hotels-legacy/client/src/services/regulator';
import {
  getCugOverrideFromUrl,
  getCurrentIntParam,
} from '../../hotels-legacy/client/src/services/url/url';
import { withConfiguration } from '../../hotels-legacy/client/src/skyscanner-application/configuration';
import {
  FormattedMessage,
  withI18n,
} from '../../hotels-legacy/client/src/skyscanner-application/i18n';
import stayLength from '../../hotels-legacy/common/src/services/guest-stay/stay-length';
import { getPricePolicyText } from '../../hotels-legacy/common/src/services/price-policy';
import { PRICE_TYPE_PER_NIGHT } from '../../hotels-legacy/common/src/services/price-type';
import Distance from '../Distance';
import DistancePOI from '../DistancePOI';
import {
  Paragraph,
  ParagraphXs,
  ParagraphSm,
  InlineTextXl,
  InlineTextXxl,
} from '../HotelsCommon/Text';
import ReviewRating from '../ReviewRating';

import CardImage from './CardImage';
import CardImagesSlider from './CardImagesSlider';
import CardLayout from './CardLayout';
import CardLayoutMobile from './CardLayoutMobile';
import CardMultiImage from './CardMultiImage';
import CardRowLayout from './CardRowLayout';
import ConfidentMessage from './ConfidentMessage';
import MainPriceMobile from './MainPriceMobile';
import MainRate from './MainRate';
import MapCardLayout from './MapCardLayout';
import MobileMapCardLayout from './MobileMapCardLayout';
import NameStars from './NameStars';
import OtherRatesRow from './OtherRatesRow';
import PriceSummary from './PriceSummary';

import type {
  RecommendPriceInfoShape,
  // HotelCardReviewsShape,
} from '../../hotels-legacy/client/src/components/types';
import type { HotelCardProps } from 'common-types/types/hotels-components/HotelCard';
import type { Price } from 'common-types/types/hotels-components/types';

import STYLES from './HotelCard.module.scss';

const HOTEL_NOT_AVAILABLE_ERROR = 'HOTEL_NOT_AVAILABLE';
const IMAGE_RADIO_ACCESSIBILITY = '34%';
const IMAGE_RADIO_SHORT = '27.5%';

class HotelCard extends Component<HotelCardProps> {
  static defaultProps = {
    image: undefined,
    isMobile: false,
    fromSeoPage: undefined,
    forDesktopMapCard: false,
    selectedOnMap: false,
    onHotelCardHover: undefined,
    forMwebMap: false,
    isViewedHotel: false,
    onCardClicked: undefined,
    sortOnlyByDistance: false,
    reviewsSummaryInfo: undefined,
    distanceInfo: undefined,
    numberOfStars: undefined,
    error: undefined,
    hotelCardAccessibility: false,
    city: undefined,
    urlForDV: undefined,
    selected: false,
    loading: false,
    hasCoupon: false,
    numberOfOffers: undefined,
    funnelType: undefined,
    priceType: undefined,
    cug: undefined,
    price: undefined,
    images: undefined,
    pivotHighlight: false,
    reviewForConfidentMsgList: undefined,
    allPricesInfo: undefined,
    coupon: undefined,
    partnerId: undefined,
    relevantPOI: undefined,
    pricePolicy: undefined,
    savedPriceInfo: undefined,
    totalImages: undefined,
    removePartnerRedirectLinks: false,
    // amenities: undefined,
    // location: undefined,
    // reviews: undefined,
    // priceInfo: undefined,
    // device: false,
  };

  // componentDidMount() {
  //   mark('dayview_firstRender', true);
  // }

  shouldComponentUpdate(nextProps: HotelCardProps) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    const {
      allPricesInfo,
      city,
      configs,
      coupon,
      cug,
      distanceInfo,
      error,
      forDesktopMapCard,
      forMwebMap,
      fromSeoPage,
      funnelType,
      hasCoupon,
      hotelCardAccessibility,
      i18n,
      id,
      image,
      images,
      index,
      isMobile,
      isViewedHotel,
      loading,
      name,
      numberOfOffers,
      numberOfStars,
      onCardClicked,
      onHotelCardHover,
      partnerId,
      pivotHighlight,
      position,
      price,
      pricePolicy,
      priceType,
      relevantPOI,
      removePartnerRedirectLinks,
      reviewForConfidentMsgList,
      reviewsSummaryInfo: {
        mostPopularWith,
        reviewSummaryCount,
        reviewSummaryScore,
        reviewSummaryScoreImageUrl: reviewSummaryImage,
      } = {},
      savedPriceInfo,
      selected,
      selectedOnMap,
      sortOnlyByDistance,
      stay,
      totalImages,
      url,
      urlForDV,
    } = this.props;
    const originalPrice = (cug && cug.originalPrice) || null;
    const { hotelCardAddPartnerLogo, hotelCardShortImage } = configs;

    const prices = get(allPricesInfo, 'prices', []);
    const { checkIn, checkOut } = stay;

    const goToDVEnabled = !!(fromSeoPage && !isMobile);

    const detailsUrl = () => {
      const linkUrl = goToDVEnabled && urlForDV ? urlForDV : url;
      if (typeof window === 'undefined') {
        // This happens server-side, which is fine, as there is no need to pass on
        // CUG override in this context
        return linkUrl;
      }

      const cugOverride = getCugOverrideFromUrl(window.location.href);

      if (originalPrice && cugOverride) {
        // Modify URL query params via URL interface
        const urlObject = new URL(linkUrl);
        urlObject.searchParams.set('cugOverride', cugOverride);
        return urlObject.href;
      }

      return linkUrl;
    };

    const isFrench = complyRegulator(i18n.culture, 'FR');

    const pivotHighLightAndSelected = selected || selectedOnMap;

    const cardHighlightClsName = (enablePivotLight = false) => {
      let cardClsName;
      if (selected) {
        if (isMobile || isFrench) {
          cardClsName = STYLES['HotelCard--selected'];
        }

        if (enablePivotLight && pivotHighlight) {
          cardClsName = STYLES['HotelCard--pivotlight'];
        }
      }

      return cardClsName;
    };

    const confidentMsgNode = () => {
      if (
        !reviewForConfidentMsgList ||
        reviewForConfidentMsgList.length === 0 ||
        !reviewSummaryCount ||
        isMobile
      ) {
        return null;
      }

      return (
        <ConfidentMessage
          reviewForConfidentMsgList={reviewForConfidentMsgList}
          numberOfReviews={reviewSummaryCount}
        />
      );
    };

    const trackSearchResultSelected = (
      isMainPrice: boolean = true,
      rate?: RecommendPriceInfoShape,
      isGoToHD: boolean = true,
      mainPriceArea?: string,
    ) => {
      if (onCardClicked) {
        onCardClicked({
          id,
          cug: isMainPrice ? cug : null,
          isCoupon: !!coupon,
          hasCoupon,
          position,
          price: isMainPrice ? price : rate?.price,
          funnelType: isMainPrice ? funnelType : rate?.funnelType,
          partnerId: isMainPrice ? partnerId : rate?.partnerId,
          isMainPrice,
          isGoToHD: isGoToHD && !goToDVEnabled,
          mainPriceArea,
          stars: numberOfStars,
          goToDVEnabled,
          couponType: getDiscountType(coupon?.discountType),
          isViewedHotel,
          viewedHotelsInfo: {
            name,
            image,
            images,
            distance: distanceInfo?.distanceMeters,
            distanceInfo,
            reviewsSummaryInfo: this.props.reviewsSummaryInfo,
            relevantPOI,
            reviewForConfidentMsgList,
            url,
          },
        });
      }
      if (!isGoToHD) {
        this.props.backendGateway.saveViewedHotels({
          cityId: city,
          destinationId: getCurrentIntParam('entity_id') || id,
          historyPrice: isMainPrice ? price : rate?.price,
          hotelId: id,
        });
      }
    };

    const getImageNode = (type?: string) =>
      !isMobile && !forDesktopMapCard && !forMwebMap ? (
        <CardMultiImage
          className={STYLES.HotelCard__img}
          images={images}
          totalImages={totalImages}
          mouseEnter={type === 'enter'}
          onClick={trackSearchResultSelected}
          isFrench={isFrench}
          url={detailsUrl()}
        />
      ) : (
        <CardImage
          className={classnames(
            STYLES.HotelCard__img,
            STYLES.HotelCard__imgOnMapCard,
          )}
          image={image}
          name={name}
          forDesktopMap={forDesktopMapCard}
        />
      );

    const nameVariantNode = (
      <InlineTextXl
        className={STYLES.HotelCard__nameVariant}
        data-test-id="hotel-name"
      >
        {name}
      </InlineTextXl>
    );

    const starsNode = numberOfStars ? (
      <HotelStars stars={numberOfStars} index={index} />
    ) : undefined;

    const pricesFrom = () => (
      <ParagraphXs className={STYLES.HotelCard__pricesFrom}>
        {numberOfOffers && numberOfOffers > 0
          ? i18n.translatePlural(
              'HotelCard_label_pricesFrom',
              numberOfOffers,
              'count',
            )
          : null}
      </ParagraphXs>
    );

    const priceNode = (
      <div className={STYLES.HotelCard__price}>
        {price && i18n.formatCurrency(price)}
      </div>
    );

    const priceVariantNode = (
      <div className={STYLES.HotelCard__priceVariant}>
        {loading && (
          <BpkSpinner
            type={SPINNER_TYPES.primary}
            className={STYLES.HotelCard__priceNodeSpinner}
          />
        )}
        <InlineTextXxl>{price && i18n.formatCurrency(price)}</InlineTextXxl>
      </div>
    );

    const isDefaultPerNight = priceType === PRICE_TYPE_PER_NIGHT;

    const getNightsNum = () => {
      let numberOfNights = stayLength({ checkIn, checkOut });
      if (isDefaultPerNight) {
        numberOfNights = 1;
      }
      return numberOfNights;
    };

    const nightNumNode = () => {
      const numberOfNights = getNightsNum();

      const numLabel = (
        <FormattedMessage
          label="Price_label_forNights"
          cardinality={numberOfNights}
          data={{ num: numberOfNights }}
        />
      );

      return (
        <ParagraphXs className={STYLES.HotelCard__nightNum}>
          {numLabel}
        </ParagraphXs>
      );
    };

    const buildOriginalPriceNode = () => (
      <div className={STYLES.HotelCard__priceOriginal}>
        {originalPrice && i18n.formatCurrency(originalPrice)}
      </div>
    );

    const FSSNode = cug ? (
      <CugBadge
        discount={cug.discountPercentage}
        type={cug.type}
        isMobile={isMobile}
      />
    ) : null;

    const buildFSSBadge = () => {
      if (cug) {
        return (
          <div className={STYLES.HotelCard__priceAndFss}>
            {FSSNode}
            <div className={STYLES.HotelCard__allPrice}>
              {originalPrice && (
                <div className={STYLES.HotelCard__originalPrice}>
                  {i18n.formatCurrency(originalPrice)}
                </div>
              )}
              <InlineTextXxl className={STYLES.HotelCard__mainPrice}>
                {price && i18n.formatCurrency(price)}
              </InlineTextXxl>
            </div>
          </div>
        );
      }
      return null;
    };

    const buildCouponBadge = (mainPrice: Price) => {
      const { name: partnerName } = mainPrice;
      if (coupon && coupon.originalPrice && coupon.discountType > 0) {
        return (
          <div className={STYLES.HotelCard__priceAndFss}>
            <GeneralDirectDiscount coupon={coupon} partnerName={partnerName} />
            <div className={STYLES.HotelCard__allPrice}>
              <div className={STYLES.HotelCard__originalPrice}>
                {i18n.formatCurrency(coupon.originalPrice)}
              </div>
              <InlineTextXxl className={STYLES.HotelCard__mainPrice}>
                {price && i18n.formatCurrency(price)}
              </InlineTextXxl>
            </div>
          </div>
        );
      }
      return null;
    };

    const buildReviewSummary = () => {
      if (reviewSummaryScore && reviewSummaryCount) {
        return (
          <ReviewRating
            score={reviewSummaryScore}
            count={reviewSummaryCount}
            image={reviewSummaryImage}
            className={STYLES.HotelCard__reviewRating}
          />
        );
      }
      return null;
    };

    const buildPriceAndNumbers = () => {
      if (!numberOfOffers && loading) {
        return <BpkSpinner type={SPINNER_TYPES.primary} />;
      }

      if (error === HOTEL_NOT_AVAILABLE_ERROR) {
        return (
          <div className={STYLES.HotelCard__unavailable}>
            {i18n.translate('HotelCard_label_hotelUnavailable')}
          </div>
        );
      }

      if (coupon && coupon.originalPrice) {
        const [mainPrice] = prices;
        return buildCouponBadge(mainPrice);
      }

      if (originalPrice) {
        return (
          <>
            {FSSNode}
            {pricesFrom()}
            <div className={STYLES.HotelCard__priceAll}>
              {buildOriginalPriceNode()}
              {priceNode}
            </div>
            {nightNumNode()}
          </>
        );
      }

      return (
        <>
          {pricesFrom()}
          {numberOfOffers ? priceNode : null}
          {nightNumNode()}
        </>
      );
    };

    const seeDetailsNode =
      error === HOTEL_NOT_AVAILABLE_ERROR ? null : (
        <div className={STYLES.HotelCard__cta}>
          <BpkLoadingButton>
            {goToDVEnabled
              ? i18n.translate('OTARates_button_viewHotel')
              : i18n.translate('HotelCard_label_seeDetails')}
          </BpkLoadingButton>
        </div>
      );

    const distanceVariantNode = distanceInfo ? (
      <div className={STYLES.HotelCard__distanceAndIcon}>
        <div className={STYLES.HotelCard__distanceIcon}>
          <LocationIconSM />
        </div>
        <Paragraph className={STYLES.HotelCard__distanceVariant}>
          <Distance distance={distanceInfo} isMobile={isMobile} />
        </Paragraph>
      </div>
    ) : null;

    const distancePOINode =
      !sortOnlyByDistance && relevantPOI ? (
        <div className={STYLES.HotelCard__distanceAndIcon}>
          <div className={STYLES.HotelCard__distanceIcon}>
            <LocationIconSM />
          </div>
          <Paragraph className={STYLES.HotelCard__distanceVariant}>
            <DistancePOI relevantPOI={relevantPOI} />
          </Paragraph>
        </div>
      ) : (
        distanceVariantNode
      );

    const getOriginalPrice = () => {
      if (originalPrice) {
        return i18n.formatCurrency(originalPrice);
      }

      if (coupon) {
        return i18n.formatCurrency(coupon.originalPrice);
      }

      if (
        funnelType === 'dbook' &&
        !originalPrice &&
        savedPriceInfo &&
        numberOfOffers &&
        numberOfOffers > 1
      ) {
        const { bestPriceOnline } = savedPriceInfo;
        return i18n.formatCurrency(bestPriceOnline);
      }

      return undefined;
    };

    const getLogoNode = (allPrices: Price[]) => {
      if (!allPrices || !allPrices.length) return null;

      const [mainPrice] = allPrices;
      const {
        logoLink,
        name: partnerName,
        partnerId: mainPricePartnerId,
      } = mainPrice;
      return hotelCardAddPartnerLogo ? (
        <OTALogo
          src={logoLink}
          partnerId={mainPricePartnerId}
          name={partnerName}
          height={24}
          index={index}
        />
      ) : undefined;
    };
    const buildBadgesForMobile = (allPrices: Price[]) => {
      if (!allPrices || !allPrices.length) return null;

      const [mainPrice] = allPrices;
      const { name: partnerName } = mainPrice;
      if (coupon) {
        return (
          <div className={STYLES.HotelCard__badgeAndOriginalPrice}>
            <GeneralDirectDiscount
              coupon={coupon}
              partnerName={partnerName}
              index={index}
            />
          </div>
        );
      }
      if (cug) {
        return (
          <div className={STYLES.HotelCard__badgeAndOriginalPrice}>
            <CugBadge
              discount={cug.discountPercentage}
              type={cug.type}
              isMobile={isMobile}
              className={STYLES.HotelCard__fssBadge}
            />
          </div>
        );
      }

      return null;
    };

    const getGuestTypeNode = () => {
      if (!mostPopularWith) {
        return null;
      }

      const guestType = i18n.translate(
        `Travellertype_${mostPopularWith}_lowercase`,
      );
      return (
        <BpkBadge type={BADGE_TYPES.normal}>
          <FormattedMessage
            label="HotelCard_label_greatForActivityOrGuestType"
            data={{ activity_or_guest_type: guestType }}
          />
        </BpkBadge>
      );
    };

    const getOtherPrices = () => {
      if (removePartnerRedirectLinks) {
        return null;
      }

      if (!numberOfOffers && loading) {
        return null;
      }

      if (error === HOTEL_NOT_AVAILABLE_ERROR) {
        return null;
      }

      const [, ...otherRates] = prices || [];
      if (!otherRates || otherRates.length === 0) {
        return null;
      }
      if (!isMobile) {
        return (
          <OtherRatesRow
            otherRates={otherRates}
            onClick={trackSearchResultSelected}
            detailUrl={detailsUrl()}
          />
        );
      }
      return null;
    };

    const getUrl = () => {
      if (!numberOfOffers && loading) {
        return <BpkSpinner type={SPINNER_TYPES.primary} />;
      }

      if (error === HOTEL_NOT_AVAILABLE_ERROR) {
        return (
          <div className={STYLES.HotelCard__unavailable}>
            {i18n.translate('HotelCard_label_hotelUnavailable')}
          </div>
        );
      }
      const [lowestPrice] = prices;
      let link = detailsUrl();

      if (!goToDVEnabled && lowestPrice.funnelType === 'meta') {
        link = lowestPrice.deepLink;
      }

      return link;
    };

    const buildStayPricesSummary = () => {
      if (!prices || !prices.length) {
        return null;
      }

      // For SEO pages we've decided not to show 'total stay' price
      // so we are not passing price/priceType/additionalPrice props here
      return <PriceSummary />;
    };

    const pricePolicyNode =
      error === HOTEL_NOT_AVAILABLE_ERROR ? null : (
        <ParagraphSm className={STYLES.HotelCard__label}>
          {pricePolicy && getPricePolicyText(pricePolicy, i18n)}
        </ParagraphSm>
      );

    const distanceNode = distanceInfo ? (
      <Distance distance={distanceInfo} isMobile={isMobile} />
    ) : null;
    const ratingNode =
      reviewSummaryScore && reviewSummaryCount ? (
        <ReviewRating
          score={reviewSummaryScore}
          count={reviewSummaryCount}
          image={reviewSummaryImage}
          index={index}
        />
      ) : null;

    const detailUrl = detailsUrl();
    let imageRadio;
    if (hotelCardAccessibility) {
      if (hotelCardShortImage) {
        imageRadio = IMAGE_RADIO_SHORT;
      } else {
        imageRadio = IMAGE_RADIO_ACCESSIBILITY;
      }
    } else {
      imageRadio = undefined;
    }
    const imageSlider = (
      <CardImagesSlider
        images={images}
        hotelName={name}
        stars={starsNode}
        detailUrl={detailUrl}
        onClick={trackSearchResultSelected}
        isNewMapHotelInfo={forDesktopMapCard}
        imageRadio={imageRadio}
        hotelCardAccessibility={hotelCardAccessibility}
      />
    );
    const nameStarNode = (
      <NameStars
        hotelName={name}
        stars={starsNode}
        isNewMapHotelInfo={forDesktopMapCard}
        className={STYLES.HotelCard__nameStars}
        hotelCardAccessibility={hotelCardAccessibility}
        index={index}
      />
    );

    const mobilePriceBadge = buildBadgesForMobile(prices);
    const logoNode = getLogoNode(prices);

    const hasMobilePriceBadge = !!mobilePriceBadge;
    const distanceForMobile = distanceInfo ? (
      <div
        className={classnames(STYLES.HotelCard__distanceAndIcon, {
          [STYLES.HotelCard__distanceAndIconPart]: hasMobilePriceBadge,
          [STYLES.HotelCard__distanceAndIconAll]: !hasMobilePriceBadge,
          [STYLES.HotelCard__distanceAndIconForMwebMap]: forMwebMap,
        })}
      >
        <div className={STYLES.HotelCard__distanceIconMobile}>
          <LocationIconSM />
        </div>
        <ParagraphXs className={STYLES.HotelCard__distanceVariantMobile}>
          {distanceNode}
        </ParagraphXs>
      </div>
    ) : null;

    const getMainPrice = () => {
      if (!numberOfOffers && loading) {
        return (
          <BpkSpinner
            type={SPINNER_TYPES.primary}
            className={STYLES.HotelCard__priceSpinner}
          />
        );
      }

      if (error === HOTEL_NOT_AVAILABLE_ERROR || !prices || !prices.length) {
        return (
          <BpkCard
            className={STYLES.HotelCard__noPrice}
            onClick={() =>
              trackSearchResultSelected(
                true,
                undefined,
                isMobile ? true : funnelType === 'dbook',
                'stub',
              )
            }
            target="_blank"
            href={detailsUrl()}
            padded={false}
          >
            <div className={STYLES.HotelCard__unavailable}>
              {i18n.translate('HotelCard_label_hotelUnavailable')}
            </div>
          </BpkCard>
        );
      }

      const [mainPrice] = prices;
      const formattedOriginalPrice = getOriginalPrice();

      if (isMobile || forDesktopMapCard) {
        return (
          <MainPriceMobile
            mainPrice={mainPrice}
            isDefaultPerNight={isDefaultPerNight}
            originalNights={stayLength({ checkIn, checkOut })}
            price={price}
            priceBadge={mobilePriceBadge}
            originalPrice={formattedOriginalPrice}
            distance={distanceForMobile}
            forDesktopMapCard={forDesktopMapCard}
            forMwebMap={forMwebMap}
            loading={loading}
            hotelCardAccessibility={hotelCardAccessibility}
            index={index}
            logoNode={logoNode}
            showDivider={hotelCardShortImage}
          />
        );
      }

      return (
        <MainRate
          mainPrice={mainPrice}
          numberOfNights={getNightsNum()}
          priceNode={priceVariantNode}
          fssBadge={buildFSSBadge()}
          couponBadge={buildCouponBadge(mainPrice)}
          onClick={trackSearchResultSelected}
          // TODO: Fix url type, expect string but getUrl() can return an Element
          // @ts-ignore
          url={getUrl()}
          pricePolicy={pricePolicyNode}
          stayPricesSummary={buildStayPricesSummary()}
          detailsUrl={detailsUrl()}
          goToDVEnabled={goToDVEnabled}
        />
      );
    };

    if (forDesktopMapCard) {
      return (
        <MapCardLayout
          name={name}
          stars={starsNode}
          imageNode={getImageNode()}
          distance={distancePOINode}
          url={detailsUrl()}
          onClick={trackSearchResultSelected}
          price={getMainPrice()}
        />
      );
    }

    if (!isFrench && !isMobile) {
      return (
        <CardRowLayout
          hotelId={id}
          name={nameVariantNode}
          stars={starsNode}
          badges={getGuestTypeNode()}
          mainPrice={getMainPrice()}
          otherPrices={getOtherPrices()}
          distance={distancePOINode}
          reviews={buildReviewSummary()}
          url={detailsUrl()}
          confidentMsg={confidentMsgNode()}
          cardHighlightClsName={cardHighlightClsName(true)}
          onClick={trackSearchResultSelected}
          getImageNode={getImageNode}
          pivotHighlight={pivotHighLightAndSelected}
          onHotelCardHover={onHotelCardHover}
        />
      );
    }

    if (forMwebMap) {
      return (
        <MobileMapCardLayout
          id={id}
          imageNode={getImageNode()}
          priceInfo={getMainPrice()}
          url={detailsUrl()}
          onClick={trackSearchResultSelected}
          ratingNode={ratingNode}
          confidentMsg={confidentMsgNode()}
          distanceNode={distanceForMobile}
          hotelName={name}
          starsNode={starsNode}
        />
      );
    }

    if (isMobile) {
      return (
        <CardLayoutMobile
          imageSlider={imageSlider}
          priceInfo={getMainPrice()}
          url={detailsUrl()}
          cardHighlightClsName={cardHighlightClsName()}
          onClick={trackSearchResultSelected}
          ratingNode={ratingNode}
          confidentMsg={confidentMsgNode()}
          distanceNode={hotelCardAccessibility ? distanceNode : null}
          imageRadio={imageRadio}
          hotelCardAccessibility={hotelCardAccessibility}
          nameStarNode={hotelCardAccessibility && nameStarNode}
          index={index}
        />
      );
    }

    return (
      <CardLayout
        hotelId={id}
        name={nameVariantNode}
        stars={starsNode}
        badges={getGuestTypeNode()}
        priceAndNumbers={buildPriceAndNumbers()}
        distance={distancePOINode}
        seeDetails={seeDetailsNode}
        url={detailsUrl()}
        cardHighlightClsName={cardHighlightClsName()}
        onClick={trackSearchResultSelected}
        pricePolicy={pricePolicyNode}
        stayPricesSummary={buildStayPricesSummary()}
        reviewSummary={buildReviewSummary()}
        getImageNode={getImageNode}
        confidentMsg={confidentMsgNode()}
        onHotelCardHover={onHotelCardHover}
      />
    );
  }
}

export default withBackendContext(withConfiguration(withI18n(HotelCard)));
