import BpkStarRating from '@skyscanner/backpack-web/bpk-component-star-rating';

import { withI18n } from '../../skyscanner-application/i18n';
import { getId, getAriaHidden } from '../../utils/a11y';

import type { I18n } from 'common-types/types/hotels-components/types';

type Props = {
  i18n: I18n;
  stars?: number;
  className?: string;
  index?: number;
};

const starsLabel = (i18n: I18n) => (stars: number) =>
  i18n.translatePlural('HotelInfo_number_stars', stars, 'number');

const HotelStars = ({
  className = undefined,
  i18n,
  index = undefined,
  stars = 0,
}: Props) =>
  stars && stars > 0 ? (
    <BpkStarRating
      rating={stars}
      maxRating={stars}
      id={getId('hotel_star', index)}
      ratingLabel={starsLabel(i18n)}
      aria-hidden={getAriaHidden(index)}
      className={className}
    />
  ) : null;

export default withI18n(HotelStars);
