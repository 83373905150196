import classnames from 'classnames';

import { withI18n } from '../../skyscanner-application/i18n';
import { getId, getAriaHidden } from '../../utils/a11y';

import agodaLogo from './Img/Agoda-logo.svg';
import oyoLogo from './Img/OYO-red-logo.svg';
import getaRoomLogo from './Img/h_gt.svg';

import type { I18n } from 'common-types/types/hotels-components/types';

import STYLES from './OTALogo.module.scss';

type OTALogoProps = {
  height: number;
  src: string;
  partnerId: string;
  name: string;
  testId?: string;
  className?: string;
  index?: number;
  i18n: I18n;
  large?: boolean;
};

const svgLogos: any = {
  h_ad: agodaLogo,
  h_gt: getaRoomLogo,
};

const partnersUseSvg = ['h_oy', 'h_oi'];

const OTALogo = ({
  className = undefined,
  height,
  i18n,
  index = undefined,
  large = false,
  name,
  partnerId,
  src,
  testId = undefined,
}: OTALogoProps) => {
  let imageNode;
  let calculatedHeight;
  if (partnersUseSvg.includes(partnerId)) {
    if (large) {
      calculatedHeight = height === 40 ? 25 : 13;
    } else {
      calculatedHeight = 10;
    }
    imageNode = (
      <img
        src={oyoLogo}
        alt={name}
        height={calculatedHeight}
        data-test-id={testId}
        className={classnames({ [STYLES.OTALogo__image]: large })}
      />
    );
  } else if (svgLogos[partnerId]) {
    if (large) {
      calculatedHeight = partnerId === 'h_gt' ? height - 5 : height;
    } else {
      calculatedHeight = height;
    }
    imageNode = (
      <img
        src={svgLogos[partnerId]}
        alt={name}
        height={calculatedHeight}
        data-test-id={testId}
        className={classnames({ [STYLES.OTALogo__image]: !large })}
      />
    );
  } else {
    imageNode = (
      <img
        src={src.replace(/(.*d_ct.png)$/g, '$1?t=2022')}
        alt={name}
        height={height}
        data-test-id={testId}
      />
    );
  }

  return (
    <div
      className={classnames(
        STYLES.OTALogo,
        { [STYLES.OTALogo__flex]: !large },
        className,
      )}
      id={getId('hotel_partner', index)}
      aria-label={i18n.translate(
        'aria_partner',
        { partnerName: name },
        '{',
        '}',
        true,
      )}
      aria-hidden={getAriaHidden(index)}
    >
      {imageNode}
    </div>
  );
};

export default withI18n(OTALogo);
