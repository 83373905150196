// eslint-disable-next-line import/prefer-default-export
export const RATING_KEY_MAP: Record<string, string> = {
  rating_average: 'Rating_label_average',
  rating_below_average: 'Rating_label_belowAverage',
  rating_excellent: 'Rating_label_excellent',
  rating_good: 'Rating_label_good',
  rating_none: 'Rating_label_none',
  rating_outstanding: 'Rating_label_outstanding',
  rating_satisfactory: 'Rating_label_satisfactory',
  rating_very_good: 'Rating_label_veryGood',
  rating_with_honors: 'Rating_label_withHonors',
};
