import complyRegulator from '../../../hotels-legacy/client/src/services/regulator';
import { withI18n } from '../../../hotels-legacy/client/src/skyscanner-application/i18n';
import CardLayout from '../CardLayout';
import CardLayoutMobile from '../CardLayoutMobile';

import type { I18n } from 'common-types/types/hotels-components/types';

import STYLES from './ShimmerCard.module.scss';

const ShimmerBlock = (className: string) => <div className={className} />;

type ShimmerCardProps = {
  i18n: I18n;
  isMobile?: boolean;
};

const ShimmerCard = ({ i18n, isMobile = false }: ShimmerCardProps) => {
  const name = ShimmerBlock(STYLES.ShimmerCard__name);
  const badges = ShimmerBlock(STYLES.ShimmerCard__badges);
  const stars = ShimmerBlock(STYLES.ShimmerCard__stars);
  const distance = ShimmerBlock(STYLES.ShimmerCard__distance);
  const price = ShimmerBlock(STYLES.ShimmerCard__price);
  const seeDetails = ShimmerBlock(STYLES.ShimmerCard__seeDetails);
  const amenities = ShimmerBlock(STYLES.ShimmerCard__amenities);

  const isFrench = complyRegulator(i18n.culture, 'FR');

  if (isMobile) {
    const ratingNode = ShimmerBlock(STYLES.ShimmerCard__ratingNode);
    const confidentMsg = (
      <div className={STYLES.ShimmerCard__confidentMsg}>
        <div className={STYLES.ShimmerCard__reviewRating} />
        <div className={STYLES.ShimmerCard__confidentItem} />
      </div>
    );
    const priceInfo = (
      <div>
        <div className={STYLES.ShimmerCard__mainPrice}>
          {distance}
          {price}
        </div>
        {amenities}
      </div>
    );

    return (
      <CardLayoutMobile
        cardHighlightClsName={STYLES.ShimmerCard__mobile}
        priceInfo={priceInfo}
        ratingNode={ratingNode}
        confidentMsg={confidentMsg}
      />
    );
  }

  const wrapperClassName = [STYLES.ShimmerCard];
  if (!isFrench) {
    wrapperClassName.push(STYLES.ShimmerCard__ticket);
  }

  return (
    <div className={wrapperClassName.join(' ')}>
      <CardLayout
        name={name}
        badges={badges}
        stars={stars}
        distance={distance}
        priceAndNumbers={price}
        seeDetails={seeDetails}
      />
    </div>
  );
};

export default withI18n(ShimmerCard);
