import classnames from 'classnames';

import BpkBreakpoint, {
  BREAKPOINTS,
} from '@skyscanner/backpack-web/bpk-component-breakpoint';

import { withI18n } from '../../hotels-legacy/client/src/skyscanner-application/i18n';
import HotelPricePolicy from '../HotelPricePolicy';

import type { I18nShape } from '../../hotels-legacy/client/src/skyscanner-application/i18n';
import type { Stay } from 'common-types/types/HotelStay';
import type { HotelPricePolicy as HotelPricePolicyValue } from 'common-types/types/hotels-components/types';

import STYLES from './HotelSearchResultsCaption.module.scss';

type Props = {
  i18n: I18nShape;
  stay: Stay;
  pricePolicy?: HotelPricePolicyValue;
  className?: string;
  loading?: boolean;
};

const HotelSearchResultsCaption = ({
  className = undefined,
  i18n,
  loading = false,
  pricePolicy = undefined,
  stay: { numberOfAdults },
}: Props) => (
  <div className={classnames(STYLES.HotelSearchResultsCaption, className)}>
    <BpkBreakpoint query={BREAKPOINTS.ABOVE_MOBILE}>
      {(isAboveMobile: boolean) =>
        isAboveMobile && (
          <>
            {i18n.translate(
              'LABEL_RESULTS_price_per_room_per_night_description',
              {
                1: i18n.translatePlural(
                  'HotelStay_label_adults_',
                  numberOfAdults || 2,
                  '0',
                  {},
                  9,
                  'X',
                ),
              },
            )}
          </>
        )
      }
    </BpkBreakpoint>
    {loading && !pricePolicy && (
      <div className={STYLES.HotelSearchResultsCaption__shimmer} />
    )}
    {pricePolicy && <HotelPricePolicy pricePolicy={pricePolicy} />}
  </div>
);

export default withI18n(HotelSearchResultsCaption);
