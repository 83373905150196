import classnames from 'classnames';

import { withI18n } from '../../../hotels-legacy/client/src/skyscanner-application/i18n';
import { ParagraphSm } from '../../HotelsCommon/Text';

import type { I18n } from 'common-types/types/hotels-components/types';

import STYLES from './PriceSummary.module.scss';

type PriceSummaryProps = {
  i18n: I18n;
};

const PriceSummary = ({ i18n }: PriceSummaryProps) => {
  const mainPriceNode = () => {
    const mainPriceLabel = 'OTARate_label_priceForANight';

    const classNameForMain = classnames(
      STYLES.PriceSummary__label,
      STYLES.PriceSummary__labelOnlyText,
    );

    return (
      <ParagraphSm className={classNameForMain}>
        {i18n.translate(mainPriceLabel, {
          price: null,
        })}
      </ParagraphSm>
    );
  };

  return <>{mainPriceNode()}</>;
};

export default withI18n(PriceSummary);
