import { Component, createRef } from 'react';

import classnames from 'classnames';
import throttle from 'lodash/throttle';

import lowRes from '../img/defaultProgressiveImage.jpg';

import STYLES from './ProgressivePicture.module.scss';

type Props = {
  src: string;
  alt: string;
  className?: string;
  lowQualityClassName?: string;
  containerButtonClassName?: string;
  dataTestId?: string;
  showShimmerAnimation?: boolean;
  lazyLoad?: boolean;
  sources: Array<{
    media?: string;
    srcSet: string[];
  }>;
};

type State = {
  imageQuality: string;
  inViewport: boolean;
};

const defaultProps = {
  className: null,
  lowQualityClassName: null,
  containerButtonClassName: null,
  showShimmerAnimation: false,
  dataTestId: null,
  lazyLoad: false,
};

const THROTTLE_TIME = 200;

class ProgressivePicture extends Component<Props, State> {
  static defaultProps = defaultProps;

  imageRef: any;

  imageContainerRef: any;

  throttleLazyLoadImage: any;

  constructor(props: Props) {
    super(props);

    this.imageRef = createRef();
    this.imageContainerRef = createRef();
    this.state = {
      imageQuality: 'low',
      inViewport: false,
    };
  }

  componentDidMount() {
    const { lazyLoad } = this.props;

    if (lazyLoad) {
      // first time load
      this.lazyLoadImage();

      this.throttleLazyLoadImage = throttle(this.lazyLoadImage, THROTTLE_TIME);

      window.addEventListener('scroll', this.throttleLazyLoadImage, true);
      window.addEventListener('resize', this.throttleLazyLoadImage);
    } else {
      this.handleImageLoadedBeforeDidMount();
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (
      this.props.src !== nextProps.src ||
      this.state.inViewport !== nextState.inViewport ||
      this.state.imageQuality !== nextState.imageQuality
    ) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttleLazyLoadImage);
    window.removeEventListener('resize', this.throttleLazyLoadImage);
  }

  handleImageLoadedBeforeDidMount = () => {
    if (this.imageRef.current && this.imageRef.current.complete) {
      this.setState({
        imageQuality: 'high',
      });
    }
  };

  lazyLoadImage = () => {
    if (
      (!this.imageRef.current ||
        (this.imageRef.current && !this.imageRef.current.complete)) &&
      this.imageContainerRef.current
    ) {
      // if image has loaded, not excuted this code again
      const clientHeight = window.innerHeight;
      const bound = this.imageContainerRef.current.getBoundingClientRect();

      if (bound.top <= clientHeight) {
        this.setState({
          inViewport: true,
        });
      }
    }
  };

  onImageLoad = () => {
    this.setState({
      imageQuality: 'high',
    });
  };

  render() {
    const { imageQuality, inViewport } = this.state;
    const {
      alt,
      className,
      containerButtonClassName,
      dataTestId,
      lazyLoad,
      lowQualityClassName,
      showShimmerAnimation,
      sources,
      src,
    } = this.props;

    const imageNode = (
      <picture
        className={STYLES.ProgressivePicture__img}
        style={{
          opacity: imageQuality === 'high' ? 1 : 0,
        }}
      >
        {sources.map((source) => (
          <source
            key={source.srcSet.join(',')}
            media={source.media}
            srcSet={source.srcSet.join(', ')}
          />
        ))}
        <img
          className={className}
          src={src}
          alt={alt}
          ref={this.imageRef}
          onLoad={this.onImageLoad}
        />
      </picture>
    );

    return (
      <div
        data-test-id={dataTestId}
        className={classnames(
          STYLES.ProgressivePicture,
          containerButtonClassName,
        )}
        ref={this.imageContainerRef}
      >
        {!showShimmerAnimation && (
          <img
            className={classnames(
              className,
              lowQualityClassName,
              STYLES.ProgressivePicture__img,
              STYLES.ProgressivePicture__lowQuality,
            )}
            src={lowRes}
            alt={alt}
            style={{
              opacity: imageQuality === 'low' ? 1 : 0,
            }}
          />
        )}

        {showShimmerAnimation && (
          <section
            className={classnames(
              className,
              STYLES.ProgressivePicture__img,
              STYLES.ProgressivePicture__shimmer,
            )}
            style={{
              opacity: imageQuality === 'low' ? 1 : 0,
            }}
          />
        )}
        {lazyLoad && inViewport && imageNode}
        {!lazyLoad && imageNode}
      </div>
    );
  }
}

export default ProgressivePicture;
