import type { ReactNode } from 'react';

import classnames from 'classnames';

import {
  BpkSpinner,
  SPINNER_TYPES,
} from '@skyscanner/backpack-web/bpk-component-spinner';

import {
  FormattedMessage,
  withI18n,
} from '../../../hotels-legacy/client/src/skyscanner-application/i18n';
import {
  getId,
  getAriaHidden,
} from '../../../hotels-legacy/client/src/utils/a11y';
import {
  InlineTextHeading4,
  InlineTextSm,
  InlineTextXs,
} from '../../HotelsCommon/Text';
import RoomRateAmenities from '../RoomRateAmenities';

import type { I18n, Price } from 'common-types/types/hotels-components/types';

import STYLES from './MainPriceMobile.module.scss';

type MainPriceMobileProps = {
  i18n: I18n;
  mainPrice: Price;
  priceBadge?: ReactNode;
  price?: number;
  isDefaultPerNight: boolean;
  originalNights: number;
  distance?: ReactNode;
  originalPrice?: string;
  forDesktopMapCard?: boolean;
  forMwebMap?: boolean;
  loading?: boolean;
  hotelCardAccessibility?: boolean;
  index?: number;
  logoNode?: ReactNode;
  showDivider?: boolean;
};

const MainPriceMobile = ({
  distance = undefined,
  forDesktopMapCard = false,
  forMwebMap = false,
  hotelCardAccessibility = false,
  i18n,
  index = undefined,
  isDefaultPerNight,
  loading = false,
  logoNode = undefined,
  mainPrice,
  originalNights,
  originalPrice = undefined,
  price = undefined,
  priceBadge = undefined,
  showDivider = false,
}: MainPriceMobileProps) => {
  const { additionalPrice, amenities } = mainPrice;
  const originalPriceAriaOneNight = i18n.translate(
    'aria_originPriceOneNight',
    {
      price: originalPrice,
    },
    '{',
    '}',
    true,
  );
  const originalPriceAriaTotal = i18n.translate(
    'aria_originPriceTotal',
    {
      price: originalPrice,
    },
    '{',
    '}',
    true,
  );
  const buildPriceInfo = () => {
    const additionalPriceWithC = additionalPrice
      ? i18n.formatCurrency(additionalPrice)
      : undefined;
    let additionalPriceText = null;
    if (originalNights === 1) {
      const mainKey = 'dayView_hotel_card_a_night';
      const mainPriceText = <FormattedMessage label={mainKey} />;
      return {
        mainPriceText,
        additionalPriceText,
        originalPriceAria: originalPriceAriaOneNight,
      };
    }

    if (isDefaultPerNight) {
      additionalPriceText = (
        <FormattedMessage
          label="OTARate_label_priceForNights"
          cardinality={originalNights}
          data={{ price: additionalPriceWithC }}
        />
      );
      return {
        mainPriceText: <FormattedMessage label="dayView_hotel_card_a_night" />,
        additionalPriceText,
        originalPriceAria: originalPriceAriaOneNight,
      };
    }
    additionalPriceText = (
      <FormattedMessage
        label="OTARate_label_priceForANight"
        data={{ price: additionalPriceWithC }}
      />
    );
    return {
      mainPriceText: <FormattedMessage label="dayView_hotel_card_total" />,
      additionalPriceText,
      originalPriceAria: originalPriceAriaTotal,
    };
  };

  const priceInfoDesc = buildPriceInfo();
  const InlineTextSubPrice = hotelCardAccessibility
    ? InlineTextXs
    : InlineTextSm;
  const cleanAndPrice = (
    <div
      className={classnames(STYLES.cleanAndPrice, {
        [STYLES.cleanAndPriceNoWrap]: forMwebMap,
        [STYLES.accessibility]: hotelCardAccessibility,
      })}
    >
      {!priceBadge && logoNode}
      <div
        className={classnames(STYLES.subMainPriceInfo, {
          [STYLES.accessibility]: hotelCardAccessibility,
        })}
      >
        {loading && (
          <BpkSpinner
            type={SPINNER_TYPES.primary}
            className={STYLES.priceNodeSpinner}
          />
        )}
        {originalPrice && (
          <InlineTextSubPrice
            id={getId('hotel_original_price', index)}
            aria-label={priceInfoDesc.originalPriceAria}
            aria-hidden={getAriaHidden(index)}
            className={classnames(STYLES.originalPrice, {
              [STYLES.accessibility]: hotelCardAccessibility,
            })}
          >
            {originalPrice}
          </InlineTextSubPrice>
        )}
        <InlineTextHeading4
          className={classnames(STYLES.mainPrice, {
            [STYLES.accessibility]: hotelCardAccessibility,
          })}
          id={getId('hotel_price', index)}
          aria-hidden={getAriaHidden(index)}
        >
          {price && i18n.formatCurrency(price)}
        </InlineTextHeading4>
        <InlineTextSubPrice
          className={STYLES.mainPriceText}
          id={getId('hotel_nights', index)}
          aria-hidden={getAriaHidden(index)}
        >
          {priceInfoDesc.mainPriceText}
        </InlineTextSubPrice>
      </div>
    </div>
  );

  if (forDesktopMapCard) {
    return (
      <div className={STYLES.subMainPriceInfo}>
        <InlineTextHeading4 className={STYLES.mainPrice}>
          {price && i18n.formatCurrency(price)}
        </InlineTextHeading4>
        <InlineTextSm className={STYLES.mainPriceText}>
          {priceInfoDesc.mainPriceText}
        </InlineTextSm>
      </div>
    );
  }

  if (forMwebMap) {
    return cleanAndPrice;
  }

  if (hotelCardAccessibility) {
    let badgeRow;
    if (priceBadge && logoNode) {
      badgeRow = (
        <div className={STYLES.badgeWithLogo}>
          {logoNode}
          {priceBadge}
        </div>
      );
    } else if (priceBadge) {
      badgeRow = priceBadge;
    }
    return (
      <div className={STYLES.MainPriceMobile}>
        {showDivider && <div className={STYLES.divider} />}
        {badgeRow}
        {cleanAndPrice}
        <RoomRateAmenities
          amenities={amenities}
          hotelCardAccessibility={hotelCardAccessibility}
          index={index}
        />
      </div>
    );
  }

  return (
    <div className={STYLES.MainPriceMobile}>
      <div className={STYLES.distanceAndBadge}>
        <div className={STYLES.distance}>{distance}</div>
        {priceBadge || cleanAndPrice}
      </div>
      {priceBadge ? cleanAndPrice : null}
      <RoomRateAmenities
        amenities={amenities}
        className={classnames(STYLES.amenity, STYLES.amenityItem)}
      />
    </div>
  );
};

export default withI18n(MainPriceMobile);
