const DEFAULT_LOGO_PARTNERS = ['h_mi', 'h_wp', 'h_im', 'h_r8'];
const PARTNER_TYPE = ['CRS', 'IBE'];

// eslint-disable-next-line import/prefer-default-export
export const isShowHotelName = (
  isOfficial: boolean,
  partnerId: string,
  partnerType: string,
) =>
  DEFAULT_LOGO_PARTNERS.includes(partnerId) ||
  (partnerId !== 'h_98' && isOfficial && PARTNER_TYPE.includes(partnerType));
