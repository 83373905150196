import findKey from 'lodash/findKey';

const DISCOUNT_TYPE = {
  UNSET_COUPON_TYPE: 0,
  COUPON: 1,
  DIRECT_DISCOUNT: 2,
};

const getDiscountType = (discountType?: number) =>
  findKey(
    DISCOUNT_TYPE,
    (val: number) => val === (discountType || DISCOUNT_TYPE.UNSET_COUPON_TYPE),
  );
export default DISCOUNT_TYPE;
export { getDiscountType };
