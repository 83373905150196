import type { ReactNode } from 'react';

import classnames from 'classnames';

import {
  getId,
  getAriaHidden,
} from '../../../hotels-legacy/client/src/utils/a11y';

import STYLES from './NameStars.module.scss';

type NameStarsProps = {
  hotelName: string;
  isLastImage?: boolean;
  stars?: ReactNode;
  isNewMapHotelInfo?: boolean;
  className?: string;
  hotelCardAccessibility?: boolean;
  index?: number;
};

const NameStars = ({
  className = undefined,
  hotelCardAccessibility = false,
  hotelName,
  index = undefined,
  isLastImage = false,
  isNewMapHotelInfo = false,
  stars = undefined,
}: NameStarsProps) => (
  <div
    className={classnames(STYLES.NameStars__container, className, {
      [STYLES.NameStars__lastImage]: isLastImage,
    })}
    data-testid="name-stars"
    aria-hidden={getAriaHidden(index)}
  >
    <div className={STYLES.NameStars__hotelNameWrap}>
      <div
        className={classnames(STYLES.NameStars__hotelName, {
          [STYLES['NameStars__hotelName--accessibility']]:
            hotelCardAccessibility,
        })}
        id={getId('hotel_name', index)}
        aria-hidden={getAriaHidden(index)}
      >
        {hotelName}
      </div>
    </div>
    {stars && (
      <div
        className={classnames({
          [STYLES.NameStars__stars]: !hotelCardAccessibility,
          [STYLES['NameStars__stars--accessibility']]: hotelCardAccessibility,
          [STYLES.NameStars__starsOnMapCard]: isNewMapHotelInfo,
        })}
      >
        {stars}
      </div>
    )}
  </div>
);

export default NameStars;
