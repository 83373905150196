import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import AlternativeImage from '../../../../../components/HotelsCommon/AlternativeImage';

import type { Maybe } from '../types';

import STYLES from './CardImageMobile.scss';

type Props = {
  name?: string;
  image?: Maybe<string>;
  className?: Maybe<string>;
  isNewMapHotelInfo?: Maybe<boolean>;
  radio?: string;
};

const cls = cssModules(STYLES);

const mobileSrcSets = (image: string): string =>
  [
    `${image.replace('_WxH', `_${343}x${132}`)} 1x`,
    `${image.replace('_WxH', `_${343 * 2}x${132 * 2}`)} 2x`,
    `${image.replace('_WxH', `_${343 * 4}x${132 * 4}`)} 4x`,
  ].join(', ');

const mobileLandscapeSets = (image: string): string =>
  [
    `${image.replace('_WxH', `_${414}x${158}`)} 1x`,
    `${image.replace('_WxH', `_${414 * 2}x${158 * 2}`)} 2x`,
    `${image.replace('_WxH', `_${414 * 4}x${158 * 4}`)} 4x`,
  ].join(', ');

export const getRadioStyle = (radio?: string) =>
  radio ? { style: { paddingBottom: radio } } : {};

const CardImageMobile = ({
  className = undefined,
  image = undefined,
  isNewMapHotelInfo = false,
  name = undefined,
  radio = undefined,
}: Props) => (
  <div className={cls('CardImageMobile', className)} {...getRadioStyle(radio)}>
    <picture>
      {image ? (
        <>
          <source media="(max-width: 517px)" srcSet={mobileSrcSets(image)} />
          <source
            media="(min-width: 518px) and (max-width: 700px)"
            srcSet={mobileLandscapeSets(image)}
          />
          {isNewMapHotelInfo && (
            <source media="(min-width: 701px)" srcSet={mobileSrcSets(image)} />
          )}
          <img className={cls('CardImageMobile__img')} src={image} alt={name} />
        </>
      ) : (
        <AlternativeImage alt={name} />
      )}
    </picture>
  </div>
);

export default CardImageMobile;
