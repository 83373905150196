import HotelCard from './HotelCard';
import ShimmerCard from './ShimmerCard';

const ShimmerCards = (number: number = 1, isMobile = false) =>
  Array.from(new Array(number).keys()).map((val) => (
    <ShimmerCard key={val} isMobile={isMobile} />
  ));

export default HotelCard;

export { ShimmerCards };
