import { Component } from 'react';

import logger from 'saddlebag-logger';

import { BREAKPOINTS } from '@skyscanner/backpack-web/bpk-component-breakpoint';
import BpkButton from '@skyscanner/backpack-web/bpk-component-button';
import {
  withButtonAlignment,
  withRtlSupport,
} from '@skyscanner/backpack-web/bpk-component-icon';
import ArrowIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/long-arrow-right';

import { BackendContextProvider } from '../../hotels-legacy/client/src/components/backend-context';
import BackendGateway from '../../hotels-legacy/client/src/services/backend-gateway';
import EventSource from '../../hotels-legacy/client/src/services/event-source';
import { I18nProvider } from '../../hotels-legacy/client/src/skyscanner-application/i18n';
import I18nService from '../../hotels-legacy/common/src/services/i18n/i18n-service';
import HotelSearchResultsDisplay from '../HotelSearchResultsDisplay';
import LandingPageDataStore from '../HotelsCommon/LandingPageDataStore/LandingPageDataStore';
import { isEmptySearchResults } from '../HotelsCommon/utils';

import type { I18nShape } from '../../hotels-legacy/client/src/skyscanner-application/types';
import type { LandingPageDataStoreResult } from '../HotelsCommon/LandingPageDataStore/LandingPageDataStore';
import type { HotelSearchResultsWrapperProps } from 'common-types/types/HotelSearchResultsWrapperProps';
import type { BackendGateway as BackendGatewayType } from 'common-types/types/hotels-components/types';

import STYLES from './HotelSearchResultsWrapper.module.scss';

const AlignedArrowIcon = withButtonAlignment(withRtlSupport(ArrowIcon));
type HotelSearchResultsWrapperState = {
  hotelSearchResults: any;
  isMobile?: boolean;
};

class HotelSearchResultsWrapper extends Component<
  HotelSearchResultsWrapperProps,
  HotelSearchResultsWrapperState
> {
  store: LandingPageDataStoreResult;

  backendGateway: BackendGatewayType;

  i18n: I18nShape;

  media?: MediaQueryList;

  static defaultProps = {
    filters: null,
    cachedSearchResults: null,
  };

  constructor(props: HotelSearchResultsWrapperProps) {
    super(props);

    const {
      backendUrl,
      cachedSearchResults,
      cldr,
      culture,
      isMobile: ssrIsMobile,
      translations,
      userContext,
    } = props;

    this.i18n = I18nService({
      translations,
      cldr,
      culture,
    });

    const eventSource = EventSource(logger, false);

    this.backendGateway = BackendGateway({
      backendUrl,
      userContext,
      eventSource,
      i18n: this.i18n,
    });

    this.store = LandingPageDataStore({ backendGateway: this.backendGateway });

    if (cachedSearchResults && cachedSearchResults.hotelCards) {
      this.store.setHotelSearchResults(cachedSearchResults);
    }

    this.state = {
      hotelSearchResults: this.store.getHotelSearchResults(),
      isMobile: ssrIsMobile,
    };
  }

  componentDidMount() {
    const { entityId, filters, stay } = this.props;
    const { hotelSearchResults } = this.state;
    this.media = window.matchMedia(BREAKPOINTS.MOBILE);

    this.store.addListener(this.getDataFromStoreCallback);
    window.addEventListener('resize', this.updateIsMobile);

    if (!hotelSearchResults.loading) {
      return;
    }

    this.store.startHotelSearchCycle({
      stay,
      entityId,
      filters,
    });
  }

  componentDidUpdate(
    prevProps: HotelSearchResultsWrapperProps,
    prevState: HotelSearchResultsWrapperState,
  ) {
    if (this.media?.matches !== prevState.isMobile) {
      this.updateIsMobile();
    }
  }

  componentWillUnmount() {
    this.store.removeListener(this.getDataFromStoreCallback);
    window.removeEventListener('resize', this.updateIsMobile);
  }

  getDataFromStoreCallback = () => {
    this.setState((prevState) => ({
      ...prevState,
      hotelSearchResults: this.store.getHotelSearchResults(),
    }));
  };

  updateIsMobile = () => {
    this.setState((prevState) => ({
      ...prevState,
      isMobile: this.media?.matches,
    }));
  };

  render() {
    return (
      <BackendContextProvider value={this.backendGateway}>
        <I18nProvider value={this.i18n}>
          <HotelSearchResultsDisplay
            destination={this.props.destination}
            stay={this.props.stay}
            fromSeoPage
            filters={undefined}
            hotelSearchResults={this.state.hotelSearchResults}
            isMobile={this.state.isMobile}
            removePartnerRedirectLinks={this.props.removePartnerRedirectLinks}
          />
          {!isEmptySearchResults(this.state.hotelSearchResults) && (
            <BpkButton
              className={STYLES.HotelSearchResultsWrapper__callToActionButton}
              href={this.props.dayViewUrl}
            >
              {this.props.translations.view_more_hotels_label}
              <AlignedArrowIcon />
            </BpkButton>
          )}
        </I18nProvider>
      </BackendContextProvider>
    );
  }
}

export default HotelSearchResultsWrapper;
