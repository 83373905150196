import type { I18nShape } from '../../../../client/src/skyscanner-application/i18n';
import type { HotelPricePolicy } from 'common-types/types/hotels-components/types';

export const PRICE_POLICY = {
  TAXES_NOT_INCLUDED: 'TAXES_NOT_INCLUDED',
  CITY_TAX_NOT_INCLUDED: 'CITY_TAX_NOT_INCLUDED',
  TAXES_INCLUDED: 'TAXES_INCLUDED',
} as const;

/*
 * Maps from an array that Bellboy returns as `results.price_includes` to
 * a tax policy enum we can work with.
 */
export const pricePolicy = (priceIncludes?: string[]) => {
  if (typeof priceIncludes !== 'undefined') {
    if (priceIncludes === null) {
      return PRICE_POLICY.CITY_TAX_NOT_INCLUDED;
    }
    if (priceIncludes.length === 0) {
      return PRICE_POLICY.TAXES_NOT_INCLUDED;
    }
    if (priceIncludes.includes('city_tax')) {
      return PRICE_POLICY.TAXES_INCLUDED;
    }
    return PRICE_POLICY.CITY_TAX_NOT_INCLUDED;
  }
  return null;
};

export const getPricePolicyText = (
  pricePolicyType: HotelPricePolicy,
  i18n: I18nShape,
) => {
  if (pricePolicyType === PRICE_POLICY.TAXES_INCLUDED) {
    return i18n.translate('OTARates_TaxesIncluded');
  }
  if (pricePolicyType === PRICE_POLICY.TAXES_NOT_INCLUDED) {
    return i18n.translate('dayview_taxes_not_included');
  }
  if (pricePolicyType === PRICE_POLICY.CITY_TAX_NOT_INCLUDED) {
    return i18n.translate('dayview_local_tax_not_included');
  }
  return null;
};
