import classnames from 'classnames';

import AlternativeImage from '../../HotelsCommon/AlternativeImage';

import STYLES from './CardImage.module.scss';

const iphoneSeSrcSets = (image: string) =>
  [
    `${image.replace('_WxH', `_100x100`)} 1x`,
    `${image.replace('_WxH', `_200x200`)} 2x`,
    `${image.replace('_WxH', `_400x400`)} 4x`,
  ].join(', ');

const iphoneXSrcSets = (image: string) =>
  [
    `${image.replace('_WxH', `_119x119`)} 1x`,
    `${image.replace('_WxH', `_238x238`)} 2x`,
    `${image.replace('_WxH', `_476x476`)} 4x`,
  ].join(', ');

const iphoneXPlusSrcSets = (image: string) =>
  [
    `${image.replace('_WxH', `_131x131`)} 1x`,
    `${image.replace('_WxH', `_262x262`)} 2x`,
    `${image.replace('_WxH', `_524x524`)} 4x`,
  ].join(', ');

const tabletSrcSets = (image: string) =>
  [
    `${image.replace('_WxH', `_216x216`)} 1x`,
    `${image.replace('_WxH', `_432x432`)} 2x`,
    `${image.replace('_WxH', `_864x864`)} 4x`,
  ].join(', ');

const desktopSrcSets = (image: string) =>
  [
    `${image.replace('_WxH', `_300x252`)} 1x`,
    `${image.replace('_WxH', `_600x504`)} 2x`,
    `${image.replace('_WxH', `_900x756`)} 4x`,
  ].join(', ');

const mapCardSrcSets = (image: string) =>
  [
    `${image.replace('_WxH', `_${320}x${252}`)} 1x`,
    `${image.replace('_WxH', `_${320 * 2}x${252 * 2}`)} 2x`,
    `${image.replace('_WxH', `_${252 * 4}x${132 * 4}`)} 4x`,
  ].join(', ');

const iphoneXWidth = 375;
const iphoneXPlusWidth = 414;
const tabletWidth = 517;
const desktopWidth = 700;

type Props = {
  image?: string;
  name: string;
  className?: string;
  imageClassName?: string;
  forDesktopMap?: boolean;
};

const CardImage = ({
  className = undefined,
  forDesktopMap = false,
  image = undefined,
  imageClassName = undefined,
  name,
}: Props) => {
  let pictureSources = null;
  if (image) {
    pictureSources = forDesktopMap ? (
      <source
        media={`(min-width: ${tabletWidth}px)`}
        srcSet={mapCardSrcSets(image)}
      />
    ) : (
      <>
        <source
          media={`(max-width: ${iphoneXWidth - 1}px)`}
          srcSet={iphoneSeSrcSets(image)}
        />
        <source
          media={`(min-width: ${iphoneXWidth}px) and (max-width: ${
            iphoneXPlusWidth - 1
          }px)`}
          srcSet={iphoneXSrcSets(image)}
        />
        <source
          media={`(min-width: ${iphoneXPlusWidth}px) and (max-width: ${
            tabletWidth - 1
          }px)`}
          srcSet={iphoneXPlusSrcSets(image)}
        />
        <source
          media={`(min-width: ${tabletWidth}px) and (max-width: ${
            desktopWidth - 1
          }px)`}
          srcSet={tabletSrcSets(image)}
        />
        <source
          media={`(min-width: ${desktopWidth}px)`}
          srcSet={desktopSrcSets(image)}
        />
      </>
    );
  }

  return (
    <div className={classnames(STYLES.CardImage, className)}>
      {image ? (
        <picture>
          {pictureSources}
          <img
            className={classnames(STYLES.CardImage__img, imageClassName)}
            src={image}
            alt={name}
          />
        </picture>
      ) : (
        <AlternativeImage alt={name} className={imageClassName} />
      )}
    </div>
  );
};

export default CardImage;
